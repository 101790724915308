import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { HiChevronLeft } from 'react-icons/hi';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { Style, Fill, Stroke } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { Polygon, MultiPolygon } from 'ol/geom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TimeSeriesGraph from './TimeSeriesGraph';
import Legend from './Legend';
import { FaWind } from "react-icons/fa";
import { WiHumidity, WiBarometer, WiRain } from "react-icons/wi";
import { CiTempHigh } from "react-icons/ci";
import { ThreeDots } from "react-loader-spinner";
import { AiOutlineWarning, AiOutlineCheckCircle } from "react-icons/ai";
import Card from "./Card";
function DetailAreaView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { area } = location.state || {};
  const [legendColors, setLegendColors] = useState([]);
  const [observationDate, setObservationDate] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateloading, setDateLoading] = useState(false);
  const [loadingtimeseries, setLoadingTimeSeries] = useState(false)
  const [startDate, setStartDate] = useState('2024-05-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [latitude, setLatitude] = useState(null)
  const [longitude,setLongitude] = useState(null)
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const vectorLayerRef = useRef(new VectorLayer({
    source: new VectorSource({ wrapX: false }),
    zIndex: 1000,
  }));
 

  const [weatherData, setWeatherData] = useState({
    temperature: null,
    humidity: null,
    windSpeed: null,
    pressure: null,
    rain: null,
    rainPeriod: null,
  });
  
  const [pollutionData, setPollutionData] = useState({
    aqi: null,
    co: null,
    no: null,
    no2: null,
    o3: null,
    so2: null,
    pm2_5: null,
    pm10: null,
    nh3: null,
  });


  const fetchWeatherData = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=a4727eca53146e091e3d4b5789e6398c`
      );
      const data = response.data;
  
      const rain = data.rain ? data.rain["1h"] || data.rain["3h"] : "No rain";
      const rainPeriod = data.rain
        ? data.rain["1h"]
          ? "last hour"
          : "last 3 hours"
        : "";
  
     

      setWeatherData({
        temperature: (data.main.temp - 273.15).toFixed(2),
        humidity: data.main.humidity,
        windSpeed: data.wind.speed,
        pressure: data.main.pressure,
        rain: rain,
        rainPeriod: rainPeriod,
      });
    } catch (error) {
      console.error("Error fetching weather data: ", error);
      return null;
    }
  };

  async function fetchPollutionData(lat, lng) {
    try {
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lng}&appid=a4727eca53146e091e3d4b5789e6398c`
      );
      const data = response.data.list[0];
      setPollutionData({
        aqi: data.main.aqi,             // Air Quality Index (AQI)
        co: data.components.co,         // Carbon Monoxide
        no: data.components.no,         // Nitrogen Monoxide
        no2: data.components.no2,       // Nitrogen Dioxide
        o3: data.components.o3,         // Ozone
        so2: data.components.so2,       // Sulfur Dioxide
        pm2_5: data.components.pm2_5,   // PM2.5
        pm10: data.components.pm10,     // PM10
        nh3: data.components.nh3,       // Ammonia
      });
    } catch (error) {
      console.error("Error fetching pollution data", error);
    }
  }
  
  useEffect(() => {
    if (area) {
      const [lon, lat] = area.geojson.geometry.coordinates[0][0][0];
      setLatitude(lat);
      setLongitude(lon);
    }
    
  }, [area]); // This will only trigger when `area` changes

  
  fetchWeatherData(latitude, longitude)
  fetchPollutionData(latitude, longitude);

  useEffect(() => {
    if (area) {

     
      setDateLoading(true);
      const fetchObservationDates = async () => {
        const body = {
          geometry: area.geojson,
          end_date: new Date().toISOString().split('T')[0],
        };
        try {
          const response = await fetch('https://api.terra-metrics.com/api/protected_area/obs-date/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          });
          const dates = await response.json();
          setAvailableDates(dates.sort((a, b) => new Date(b) - new Date(a)));
          setDateLoading(false);
          toast.success('Observation dates loaded successfully!');
        } catch (error) {
          console.error('Error fetching observation dates:', error);
          setDateLoading(false);
          toast.error('Failed to load observation dates.');
        }
      };
      fetchObservationDates();
    }
  }, [area]);

  const handleGetMap = async () => {
    if (!observationDate) {
      toast.error('Please select an observation date before getting the NDVI map.');
      return;
    }

    try {
      setLoading(true);
      const body = {
        geometry: area.geojson,
        date: observationDate,
      };

      const response = await fetch('https://api.terra-metrics.com/api/protected_area/ndvi/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const { legend_info, class_no_color, geojsonfeature } = await response.json();
      const { features } = geojsonfeature;
      const legend_colors = legend_info.legend_colors;
      const colorMap = class_no_color;
      setLegendColors(legend_colors);
      const olFeatures = features.map(feature => {
        const geometryType = feature.geometry.type;
        let coordinates = [];

        if (geometryType === 'Polygon') {
          coordinates = feature.geometry.coordinates[0].map(coord => fromLonLat(coord));
          const olFeature = new Feature({
            geometry: new Polygon([coordinates]),
            properties: feature.properties,
          });

          olFeature.setStyle(new Style({
            fill: new Fill({
              color: colorMap[feature.properties.class_no],
            }),
            stroke: new Stroke({
              color: '#000000',
              width: 1,
            }),
          }));

          return olFeature;
        } else if (geometryType === 'MultiPolygon') {
          const multiPolygonCoordinates = feature.geometry.coordinates.map(polygon =>
            polygon.map(ring =>
              ring.map(coord => fromLonLat(coord))
            )
          );

          const olFeature = new Feature({
            geometry: new MultiPolygon(multiPolygonCoordinates),
            properties: feature.properties,
          });

          olFeature.setStyle(new Style({
            fill: new Fill({
              color: colorMap[feature.properties.class_no],
            }),
            stroke: new Stroke({
              color: '#000000',
              width: 1,
            }),
          }));

          return olFeature;
        }
        return null;
      });

      vectorLayerRef.current.getSource().addFeatures(olFeatures.filter(f => f));
      setLoading(false);
      toast.success('NDVI map loaded successfully!');
    } catch (error) {
      console.error('Error fetching NDVI data:', error);
      setLoading(false);
      toast.error('Failed to load NDVI map.');
    }
  };

  const handleFetchTimeSeries = async () => {
    try {
      setLoadingTimeSeries(true)
      const body = {
        geometry: area.geojson,
        start_date: startDate,
        end_date: endDate,
      };

      const response = await fetch('https://api.terra-metrics.com/api/protected_area/ndvi/ts/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const timeSeries = await response.json();
      const formattedData = timeSeries.map(item => ({
        date: item.date,
        value: item.mean_ndvi,
      }));

      setTimeSeriesData(formattedData);
      setLoadingTimeSeries(false)
      toast.success('Time series data loaded successfully!');
    } catch (error) {
      setLoadingTimeSeries(false)
      console.error('Error fetching time series data:', error);
      toast.error('Failed to load time series data.');
    }
  };
  const getTemperatureStatus = (temp) => {
    if (temp < 0) return "Freezing";
    if (temp <= 15) return "Cold";
    if (temp <= 25) return "Mild";
    return "Hot";
  };

  const getPressureStatus = (pressure) => {
    if (pressure < 1010) return "Low";
    if (pressure <= 1020) return "Normal";
    return "High";
  };

  const getWindSpeedStatus = (speed) => {
    if (speed < 1) return "Calm";
    if (speed <= 5) return "Light";
    if (speed <= 10) return "Moderate";
    return "Strong";
  };

  const getHumidityStatus = (humidity) => {
    if (humidity < 30) return "Low";
    if (humidity <= 60) return "Normal";
    return "High";
  };

  const getStatus = (value, type) => {
    const thresholds = {
      aqi: {
        good: 50,
        moderate: 100,
        unhealthy: 150,
        veryUnhealthy: 200,
        hazardous: 300,
      },
      co: {
        good: 4400,
        moderate: 9400,
        unhealthy: 12400,
        veryUnhealthy: 15400,
        hazardous: 40400,
      },
      no: {
        good: 200,
        moderate: 400,
        unhealthy: 600,
        veryUnhealthy: 800,
        hazardous: 1000,
      },
      no2: {
        good: 100,
        moderate: 200,
        unhealthy: 300,
        veryUnhealthy: 400,
        hazardous: 500,
      },
      o3: {
        good: 100,
        moderate: 160,
        unhealthy: 215,
        veryUnhealthy: 265,
        hazardous: 800,
      },
      so2: {
        good: 20,
        moderate: 80,
        unhealthy: 250,
        veryUnhealthy: 500,
        hazardous: 1000,
      },
      pm2_5: {
        good: 12,
        moderate: 35.4,
        unhealthy: 55.4,
        veryUnhealthy: 150.4,
        hazardous: 250.4,
      },
      pm10: {
        good: 54,
        moderate: 154,
        unhealthy: 254,
        veryUnhealthy: 354,
        hazardous: 424,
      },
      nh3: {
        good: 200,
        moderate: 400,
        unhealthy: 800,
        veryUnhealthy: 1200,
        hazardous: 1600,
      },
    };

    const statusLevels = ["good", "moderate", "unhealthy", "veryUnhealthy", "hazardous"];

    if (!thresholds[type]) {
      return "Unknown"; // Handle unknown types
    }

    const typeThresholds = thresholds[type];
    for (let i = statusLevels.length - 1; i >= 0; i--) {
      if (value >= typeThresholds[statusLevels[i]]) {
        return statusLevels[i];
      }
    }

    return "good"; // Default status if value is lower than the lowest threshold
  };
  const getIcon = (status) => {
    switch (status) {
      case "good":
        return <AiOutlineCheckCircle className="text-green-500" />;
      case "moderate":
        return <AiOutlineCheckCircle className="text-yellow-500" />;
      case "unhealthy":
        return <AiOutlineWarning className="text-orange-500" />;
      case "veryUnhealthy":
        return <AiOutlineWarning className="text-red-500" />;
      case "hazardous":
        return <AiOutlineWarning className="text-purple-500" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!mapRef.current) {
      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
        }),
      });

      const mapInstance = new Map({
        target: mapContainerRef.current,
        layers: [vectorLayerRef.current, satelliteLayer],
        view: new View({
          center: fromLonLat([-90.4996, 15.6093]),
          zoom: 5,
        }),
      });

      mapRef.current = mapInstance;

      if (area && area.geojson) {
        const format = new GeoJSON();
        const feature = format.readFeature(area.geojson, {
          featureProjection: 'EPSG:3857',
        });
        const extent = feature.getGeometry().getExtent();
        vectorLayerRef.current.getSource().addFeature(feature);
        mapInstance.getView().fit(extent, { duration: 1000, maxZoom: 16 });
      }
    }
  }, [area]);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setObservationDate(selectedDate);
  };

  if (!area) {
    return <div className="flex justify-center items-center h-screen text-lg text-red-500">No area data available</div>;
  }

  return (
    <div className="flex flex-col justify-center bg-white w-full">
      <ToastContainer />
      <div className="flex flex-col items-center pb-5 w-full bg-slate-50 max-md:max-w-full">
        <div className="flex flex-col px-4 mt-9 w-full max-w-[960px] max-md:max-w-full">
          <div className="self-start text-3xl font-bold text-neutral-900">Forest Quality Dashboard</div>
          <div className="self-start mt-3 text-sm leading-5 text-slate-500">Guatemala</div>
          <div className="flex justify-center w-full px-4 py-3 border-b border-gray-200">
            <button
              onClick={() => navigate('/forest')}
              className="flex items-center text-blue-500 hover:text-blue-700"
            >
              <HiChevronLeft className="h-6 w-6 mr-2" />
              Back
            </button>
          </div>

          <div className="mb-6">
            <h2 className="text-2xl font-semibold text-neutral-800 mb-2">{area.name}</h2>
          </div>

          <div className="bg-white shadow-md rounded-lg p-4 mb-6 border border-gray-200">
            <h3 className="text-xl font-semibold text-neutral-800 mb-2">Area Information</h3>
            <p className="text-sm text-gray-600">Name: {area.name}</p>
            <p className="text-sm text-gray-600">Designation: {area.designation}</p>
          </div>

         

         

          <div className="flex justify-between mb-6">
            <div className="w-1/2 pr-4">
         
              {dateloading ? (
         <button type="button" className="bg-gray-700 text-white py-2 px-4 rounded-md w-full md:w-auto flex items-center justify-center space-x-3" disabled>
         <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
           <path d="M4 12a8 8 0 018-8" strokeLinecap="round" strokeWidth="4"></path>
         </svg>
         <span>Loading dates...</span>
       </button>
              ) : (
                <select
                  id="observation-date"
                  value={observationDate}
                  onChange={handleDateChange}
                  className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Select a date</option>
                  {availableDates.map((date) => (
                    <option key={date} value={date}>
                      {new Date(date).toLocaleDateString()}
                    </option>
                  ))}
                </select>
              )}
            </div>
            {!loading && (
              <button
                onClick={handleGetMap}
                className="bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-500 w-full md:w-auto"
              >
                Get NDVI Map
              </button>
            )}
            {loading && (
              <button type="button" className="bg-gray-700 text-white py-2 px-4 rounded-md w-full md:w-auto flex items-center justify-center space-x-3" disabled>
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M4 12a8 8 0 018-8" stroke="currentColor" strokeLinecap="round" strokeWidth="4"></path>
                </svg>
                <span>Processing...</span>
              </button>
            )}
          </div>

         
          <div className="w-full h-[500px] md:h-[500px] mb-6 relative">
            <div ref={mapContainerRef} className="w-full h-full" />
            {legendColors.length > 0 && (
              <div className="absolute bottom-2 right-2 bg-white p-2 border border-gray-200 shadow-md">
                <Legend legendColors={legendColors} />
              </div>
            )}
          </div>
 {/* Date selectors for time series data */}
 <div className="flex flex-col mb-6">
            <h4 className="text-xl font-semibold text-neutral-800 mb-2">Time Series Data</h4>
            <div className="flex flex-col space-y-4">
  <div className="flex space-x-4">
    <div>
      <label className="block text-gray-600 mb-2" htmlFor="start-date">Start Date</label>
      <input
        type="date"
        id="start-date"
        value={startDate}
        max={endDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
    <div>
      <label className="block text-gray-600 mb-2" htmlFor="end-date">End Date</label>
      <input
        type="date"
        id="end-date"
        value={endDate}
        max={new Date().toISOString().split('T')[0]}
        onChange={(e) => setEndDate(e.target.value)}
        className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
  </div>

  <div>
    {!loadingtimeseries ? (
      <button
        onClick={handleFetchTimeSeries}
        className="bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-500 w-full md:w-auto"
      >
        Fetch Time Series NDVI Data
      </button>
    ) : (
      <button type="button" className="bg-gray-700 text-white py-2 px-4 rounded-md w-full md:w-auto flex items-center justify-center space-x-3" disabled>
        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M4 12a8 8 0 018-8" stroke="currentColor" strokeLinecap="round" strokeWidth="4"></path>
        </svg>
        <span>Getting NDVI Timeseries Data...</span>
      </button>
    )}
  </div>
</div>

            {/* {timeSeriesData.length>0 && (
            <TimeSeriesGraph data={timeSeriesData} />
            )} */}
         
            <TimeSeriesGraph data={timeSeriesData} />
 
          </div>
          <div>
          <div className="self-start text-3xl font-bold text-neutral-900">
            Weather 
          </div>
            <div className="flex gap-3 mt-8 max-md:flex-wrap">
            <Card
              icon={<CiTempHigh className="text-lg h-8 w-8 text-[#F44336]" />}
              value={
                weatherData.temperature !== null ? (
                  `${weatherData.temperature} °C`
                ) : (
                  <ThreeDots color="#00BFFF" height={20} width={20} />
                )
              }
              unit=""
              label="Temperature"
              status={weatherData.temperature !== null ? getTemperatureStatus(weatherData.temperature) : "N/A"}
            />
            <Card
              icon={<WiBarometer className="text-lg h-8 w-8 text-[#F44336]" />}
              value={
                weatherData.pressure !== null ? (
                  `${weatherData.pressure} hPa`
                ) : (
                  <ThreeDots color="#00BFFF" height={20} width={20} />
                )
              }
              unit=""
              label="Pressure"
              status={weatherData.pressure !== null ? getPressureStatus(weatherData.pressure) : "N/A"}
            />
            <Card
              icon={<FaWind className="text-lg h-8 w-8 text-[#03A9F4]" />}
              value={
                weatherData.windSpeed !== null ? (
                  `${weatherData.windSpeed} m/s`
                ) : (
                  <ThreeDots color="#00BFFF" height={20} width={20} />
                )
              }
              unit=""
              label="Wind Speed"
              status={weatherData.windSpeed !== null ? getWindSpeedStatus(weatherData.windSpeed) : "N/A"}
            />
            <Card
              icon={<WiHumidity className="text-lg h-8 w-8 text-[#2196F3]" />}
              value={
                weatherData.humidity !== null ? (
                  `${weatherData.humidity}%`
                ) : (
                  <ThreeDots color="#00BFFF" height={20} width={20} />
                )
              }
              unit=""
              label="Humidity"
              status={weatherData.humidity !== null ? getHumidityStatus(weatherData.humidity) : "N/A"}
            />
            <Card
              icon={<WiRain className="text-lg h-8 w-8 text-[#2196F3]" />}
              value={
                weatherData.rain !== "No rain" ? (
                  <>
                    {`${weatherData.rain} mm`}
                    <span className="text-xs text-gray-500"> ({weatherData.rainPeriod})</span>
                  </>
                ) : (
                  "No rain"
                )
              }
              unit=""
              label="Rain"
            
            />
          </div>
          </div>
          <div>    <div className="self-start text-3xl font-bold text-neutral-900">
            Air Quality
          </div>
          <div className="flex gap-3 mt-8 max-md:flex-wrap">
            <Card
              icon={getIcon(getStatus(pollutionData.aqi, "aqi"))}
              value={pollutionData.aqi}
              unit=""
              label="Air Quality Index"
              status={getStatus(pollutionData.aqi, "aqi")}
            />
            <Card
              icon={getIcon(getStatus(pollutionData.co, "co"))}
              value={pollutionData.co}
              unit="µg/m³"
              label="CO"
              status={getStatus(pollutionData.co, "co")}
            />
                 <Card
              icon={getIcon(getStatus(pollutionData.no, "no"))}
              value={pollutionData.no}
              unit="µg/m³"
              label="NO"
              status={getStatus(pollutionData.no, "no")}
            />
            <Card
              icon={getIcon(getStatus(pollutionData.no2, "no2"))}
              value={pollutionData.no2}
              unit="µg/m³"
              label="NO₂"
              status={getStatus(pollutionData.no2, "no2")}
            />
            </div>
             <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <Card
              icon={getIcon(getStatus(pollutionData.o3, "o3"))}
              value={pollutionData.o3}
              unit="µg/m³"
              label="O3"
              status={getStatus(pollutionData.o3, "o3")}
            />
               <Card
              icon={getIcon(getStatus(pollutionData.so2, "so2"))}
              value={pollutionData.so2}
              unit="µg/m³"
              label="SO₂"
              status={getStatus(pollutionData.no2, "so2")}
            />
            <Card
              icon={getIcon(getStatus(pollutionData.pm2_5, "pm2_5"))}
              value={pollutionData.pm2_5}
              unit="µg/m³"
              label="PM2.5"
              status={getStatus(pollutionData.pm2_5, "pm2_5")}
            />
            <Card
              icon={getIcon(getStatus(pollutionData.pm10, "pm10"))}
              value={pollutionData.pm10}
              unit="µg/m³"
              label="PM10"
              status={getStatus(pollutionData.pm10, "pm10")}
            />
             <Card
              icon={getIcon(getStatus(pollutionData.nh3, "nh3"))}
              value={pollutionData.nh3}
              unit="µg/m³"
              label="NH3"
              status={getStatus(pollutionData.nh3, "nh3")}
            />


          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailAreaView;
