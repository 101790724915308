import React, { useEffect, useRef } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import WMSSource from 'ol/source/TileWMS';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import { Style, Stroke } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';

function FireMap() {
  const wmsLayerRef = useRef(null);

  // Get the current date and calculate 10 days ago
  const currentDate = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(currentDate.getDate() - 10);

  // Format dates as YYYY-MM-DD
  const currentDateString = currentDate.toISOString().split('T')[0];
  const tenDaysAgoString = tenDaysAgo.toISOString().split('T')[0];

  useEffect(() => {
    // Create WMS Source for fire data
    const wmsSource = new WMSSource({
      url: 'https://services.sentinel-hub.com/ogc/wms/0b705482-3943-4a03-835c-aa6340f3b2fb',
      params: {
        'LAYERS': 'FIRE',
        'FORMAT': 'image/png',
        'TRANSPARENT': true,
        'SHOWLOGO': false,
        'TIME': `${tenDaysAgoString}/${currentDateString}`, // Set the time range for fire data
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous',
    });

    // Create a tile layer for the satellite basemap using Google Maps XYZ tiles
    const satelliteLayer = new TileLayer({
      source: new XYZ({
        url: 'http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', // Satellite tiles from Google Maps
      }),
    });

    // Create the WMS tile layer for fire data
    const wmsLayer = new TileLayer({
      source: wmsSource,
      visible: true, // Set visibility based on your requirement
    });

    // Load GeoJSON data from public directory
    const vectorSource = new VectorSource({
      format: new GeoJSON(),
      url: '/guatemala.geojson' // Path to the GeoJSON file in the public folder
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        stroke: new Stroke({
          color: 'yellow', // Boundary color
          width: 4, // Line width
          lineDash: [4, 8], // Dotted line style: [dash length, space length]
        }),
        fill: null, // No fill
      }),
    });

    // Center map to Guatemala's coordinates (longitude, latitude)
    const guatemalaCoordinates = [-90.2308, 15.7835]; // Guatemala's center in lon/lat

    // Create the map instance and add all layers
    const map = new Map({
      target: 'map', // The DOM element to render the map in
      layers: [
        satelliteLayer, // Add the satellite basemap layer
        wmsLayer, // Add the WMS layer on top
        vectorLayer, // Add the vector layer with GeoJSON boundaries
      ],
      view: new View({
        center: fromLonLat(guatemalaCoordinates), // Center the map to Guatemala
        zoom: 10, // Adjust zoom level
      }),
    });

    // Save WMS layer reference for future use
    wmsLayerRef.current = wmsLayer;

    return () => {
      // Cleanup if necessary when the component is unmounted
      if (map) {
        map.setTarget(null);
      }
    };
  }, [currentDateString, tenDaysAgoString]);

  return (
    <div>
      {/* The map will be rendered inside this div */}
      <div id="map" style={{ width: '100%', height: '500px' }}></div>

      {/* Legend for the map */}
      <div style={{ padding: '10px', backgroundColor: '#f8f8', border: '1px solid #ccc', marginTop: '10px' }}>
      
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          <li>
            <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: 'red', marginRight: '10px' }}></span>
            Active Fire Areas (Red)
          </li>
          <li>
            <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: 'blue', marginRight: '10px' }}></span>
            Cloud Mask (Blue)
          </li>
          <li>
            <span style={{ display: 'inline-block', width: '20px', height: '20px', border: '2px dashed yellow', marginRight: '10px' }}></span>
            Guatemala Boundary (Yellow Dotted Line)
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FireMap;
