import React, { useState } from "react";
import { FaTree, FaShieldAlt } from "react-icons/fa"; // Import icons
import ProtectedArea from "./ProtectedArea";
import ForestWatch from "./ForestWatch";

function Forest() {
  const [activeComponent, setActiveComponent] = useState("ProtectedArea");

  return (
    <div className="flex flex-col justify-center bg-gray-100 min-h-screen w-full">
      <div className="flex flex-col items-center pb-5 w-full bg-gray-50">
        <div className="flex flex-col px-4 mt-9 w-full max-w-[960px] mx-auto">
          <div className="text-3xl font-bold text-gray-900 mb-4">
            Forest Quality Dashboard
          </div>
          <div className="text-sm leading-5 text-gray-600 mb-6">
            Guatemala
          </div>

          {/* Buttons to switch between components */}
          <div className="flex mb-4 ml-4">
  <button
    onClick={() => setActiveComponent("ProtectedArea")}
    className={`flex items-center px-4 py-2 rounded ${
      activeComponent === "ProtectedArea" ? "bg-gray-700 text-white" : "bg-gray-200 text-gray-700"
    }`}
  >
    <FaShieldAlt className="mr-2" /> {/* Protected Area Icon */}
    Protected Area
  </button>
  <button
    onClick={() => setActiveComponent("ForestWatch")}
    className={`flex items-center px-4 py-2 ml-4 rounded ${
      activeComponent === "ForestWatch" ? "bg-gray-700 text-white" : "bg-gray-200 text-gray-700"
    }`}
  >
    <FaTree className="mr-2" /> {/* Forest Watch Icon */}
    Forest Watch
  </button>
</div>


          {/* Conditionally render the components based on the activeComponent state */}
          {activeComponent === "ForestWatch" && <ForestWatch />}
          {activeComponent === "ProtectedArea" && <ProtectedArea />}
        </div>
      </div>
    </div>
  );
}

export default Forest;
