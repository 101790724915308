import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { NavigationControl, FullscreenControl } from 'maplibre-gl';

const Map = ({ onPointClick, selectedPoint }) => {
  const mapPanelRef = useRef(null);
  const mapRef = useRef(null);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [animatedPoint, setAnimatedPoint] = useState(null);

  useEffect(() => {
    const fetchGeoJSON = async () => {
      try {
        const response = await fetch('/water_bodies.geojson');
        const geojson = await response.json();
        setFilteredFeatures(geojson.features);
      } catch (error) {
        console.error('Error fetching GeoJSON:', error);
      }
    };

    fetchGeoJSON();
  }, []);

  useEffect(() => {
    if (mapPanelRef.current && filteredFeatures.length > 0 && !mapRef.current) {
      mapRef.current = new maplibregl.Map({
        container: mapPanelRef.current,
        style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=Otbh9YhFMbwux7HyoffB',
        center: [-90.2308, 15.7835],
        zoom: 6,
        pitch: 20,
      });

      mapRef.current.addControl(new NavigationControl(), 'top-right');
      mapRef.current.addControl(new FullscreenControl(), 'bottom-right');

      mapRef.current.on('load', () => {
        mapRef.current.addSource('gtm', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: filteredFeatures,
          },
        });

        mapRef.current.addLayer({
          id: 'gtm-point-layer',
          type: 'circle',
          source: 'gtm',
          paint: {
            'circle-radius': 6,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff',
            'circle-color': '#FF5733', // Adjust color if needed
          },
        });

        mapRef.current.on('click', 'gtm-point-layer', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();

          if (onPointClick) {
            onPointClick({ lat: coordinates[1], lng: coordinates[0] });
          }

          setAnimatedPoint(e.features[0]);
        });
      });
    }
  }, [filteredFeatures, onPointClick]);

  useEffect(() => {
    if (mapRef.current && selectedPoint) {
      const updatedFeatures = filteredFeatures.map((feature) => {
        if (feature.geometry.coordinates[0] === selectedPoint.lng && feature.geometry.coordinates[1] === selectedPoint.lat) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              selected: true,
            },
          };
        } else {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              selected: false,
            },
          };
        }
      });

      mapRef.current.getSource('gtm').setData({
        type: 'FeatureCollection',
        features: updatedFeatures,
      });

      setAnimatedPoint(updatedFeatures.find(feature => feature.geometry.coordinates[0] === selectedPoint.lng && feature.geometry.coordinates[1] === selectedPoint.lat));
    }
  }, [selectedPoint, filteredFeatures]);

  useEffect(() => {
    if (mapRef.current && animatedPoint) {
      let radius = 6;
      const animateCircleRadius = () => {
        radius = radius === 6 ? 12 : 6;
        mapRef.current.setPaintProperty('gtm-point-layer', 'circle-radius', radius);
        requestAnimationFrame(animateCircleRadius);
      };
      animateCircleRadius();
    }
  }, [animatedPoint]);

  return (
    <div className="w-full h-96 relative">
      <div ref={mapPanelRef} className="w-full h-full" />
    </div>
  );
};

export default Map;
