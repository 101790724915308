import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { WiNightAltRain } from 'react-icons/wi';
import { FaWind, FaWater, FaTree, FaBug, FaEye, FaBars } from 'react-icons/fa';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Close the menu when the location changes (i.e., when a new menu item is selected)
    setMenuOpen(false);
  }, [location]);

  const isActive = (path) => {
    // Update the logic to handle multiple active paths
    if (path === '/species') {
      return location.pathname === '/species' || location.pathname === '/detailspeciesview'
        ? 'bg-gray-700 text-white font-bold border-2 border-gray-600 shadow-lg'
        : 'text-gray-800';
    }
    if (path === '/forest') {
      return location.pathname === '/forest' || location.pathname === '/detailareaview'
        ? 'bg-gray-700 text-white font-bold border-2 border-gray-600 shadow-lg'
        : 'text-gray-800';
    }
    return location.pathname === path
      ? 'bg-gray-700 text-white font-bold border-2 border-gray-600 shadow-lg'
      : 'text-gray-800';
  };

  const hoverStyle = 'hover:bg-gray-600 hover:text-white transition-colors duration-300';

  const iconMap = {
    '/': WiNightAltRain,
    '/air': FaWind,
    '/water': FaWater,
    '/forest': FaTree,
    '/species': FaBug,
    '/observations': FaEye,
  };

  return (
    <div className="flex flex-col w-full items-center header-container">
      <div className="flex justify-between w-full px-4 py-2 rounded-md bg-gradient-to-r bg-gray-700 to-indigo-600 shadow-md sm:hidden">
        <div className="text-white font-bold text-xl">Environmental Monitoring Platform</div>
        <button
          className="text-white text-2xl focus:outline-none"
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label="Toggle menu"
        >
          <FaBars />
        </button>
      </div>
      <div className={`flex-col ${menuOpen ? 'flex' : 'hidden'} header-container-menu-bar sm:flex flex-wrap sm:flex-row gap-2 sm:gap-4 pt-4 w-full justify-center`}>
        {Object.keys(iconMap).map((path) => (
          <Link
            key={path}
            to={path}
            onClick={() => setMenuOpen(false)} // Collapse the menu on item click
            className={`w-full sm:w-[150px] h-10 px-1 py-1 rounded-lg cursor-pointer text-center flex items-center justify-center font-poppins border-2 ${isActive(
              path
            )} ${hoverStyle} text-xs sm:text-sm`}
          >
            {React.createElement(iconMap[path], { className: 'mr-1' })} 
            {path === '/' ? 'Weather' : path.slice(1)}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Header;
