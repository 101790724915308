import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineWarning, AiOutlineCheckCircle } from "react-icons/ai";
import Map from "./Map";
import Card from "./Card";
import GCard from "./GCard";

const processData = (forecast) => {
  const processForecastData = (data) => {
    return data.slice(3, 7).map(entry => ({
      date: entry.day,
      value: entry.avg,
    }));
  };

  const o3Data = processForecastData(forecast.daily.o3);
  const pm10Data = processForecastData(forecast.daily.pm10);
  const pm25Data = processForecastData(forecast.daily.pm25);

  return { o3Data, pm10Data, pm25Data };
};

function Air() {
  const [lat, setLat] = useState(14.628434);
  const [lng, setLng] = useState(-90.522713);
  const [locationName, setLocationName] = useState("Guatemala City, Guatemala");
  const [stationsData, setStationsData] = useState([]);
  const [geojsonFeatures, setGeojsonFeatures] = useState([]);

  const handlePointClick = (pointInfo) => {
    setLat(pointInfo.lat);
    setLng(pointInfo.lng);
    setLocationName(`${pointInfo.ADM1_ES || " "}, Guatemala`);
  };

  const [pollutionData, setPollutionData] = useState({
    aqi: null,
    co: null,
    no: null,
    no2: null,
    o3: null,
    so2: null,
    pm2_5: null,
    pm10: null,
    nh3: null,
  });

  const [forecastData, setForecastData] = useState({
    o3Data: [],
    pm10Data: [],
    pm25Data: [],
  });

  const getStatus = (value, type) => {
    const thresholds = {
      aqi: {
        good: 50,
        moderate: 100,
        unhealthy: 150,
        veryUnhealthy: 200,
        hazardous: 300,
      },
      co: {
        good: 4400,
        moderate: 9400,
        unhealthy: 12400,
        veryUnhealthy: 15400,
        hazardous: 40400,
      },
      no: {
        good: 200,
        moderate: 400,
        unhealthy: 600,
        veryUnhealthy: 800,
        hazardous: 1000,
      },
      no2: {
        good: 100,
        moderate: 200,
        unhealthy: 300,
        veryUnhealthy: 400,
        hazardous: 500,
      },
      o3: {
        good: 100,
        moderate: 160,
        unhealthy: 215,
        veryUnhealthy: 265,
        hazardous: 800,
      },
      so2: {
        good: 20,
        moderate: 80,
        unhealthy: 250,
        veryUnhealthy: 500,
        hazardous: 1000,
      },
      pm2_5: {
        good: 12,
        moderate: 35.4,
        unhealthy: 55.4,
        veryUnhealthy: 150.4,
        hazardous: 250.4,
      },
      pm10: {
        good: 54,
        moderate: 154,
        unhealthy: 254,
        veryUnhealthy: 354,
        hazardous: 424,
      },
      nh3: {
        good: 200,
        moderate: 400,
        unhealthy: 800,
        veryUnhealthy: 1200,
        hazardous: 1600,
      },
    };

    const statusLevels = ["good", "moderate", "unhealthy", "veryUnhealthy", "hazardous"];

    if (!thresholds[type]) {
      return "Unknown"; // Handle unknown types
    }

    const typeThresholds = thresholds[type];
    for (let i = statusLevels.length - 1; i >= 0; i--) {
      if (value >= typeThresholds[statusLevels[i]]) {
        return statusLevels[i];
      }
    }

    return "good"; // Default status if value is lower than the lowest threshold
  };

  const getIcon = (status) => {
    switch (status) {
      case "good":
        return <AiOutlineCheckCircle className="text-green-500" />;
      case "moderate":
        return <AiOutlineCheckCircle className="text-yellow-500" />;
      case "unhealthy":
        return <AiOutlineWarning className="text-orange-500" />;
      case "veryUnhealthy":
        return <AiOutlineWarning className="text-red-500" />;
      case "hazardous":
        return <AiOutlineWarning className="text-purple-500" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    async function fetchPollutionData() {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lng}&appid=a4727eca53146e091e3d4b5789e6398c`
        );
        const data = response.data.list[0];
        setPollutionData({
          aqi: data.main.aqi,
          co: data.components.co,
          no: data.components.no,
          no2: data.components.no2,
          o3: data.components.o3,
          so2: data.components.so2,
          pm2_5: data.components.pm2_5,
          pm10: data.components.pm10,
          nh3: data.components.nh3,
        });
      } catch (error) {
        console.error("Error fetching pollution data", error);
      }
    }

    if (lat !== null && lng !== null) {
      fetchPollutionData();
    }
  }, [lat, lng]);

  useEffect(() => {
    async function fetchForecastData() {
      try {
        const response = await axios.get(
          `https://api.waqi.info/feed/geo:${lat};${lng}/?token=9c5774c8fe025ca6e06d55cd05823fb4e5192438`
        );
        const data = response.data.data;
        const processedData = processData(data.forecast);
        setForecastData(processedData);
      } catch (error) {
        console.error("Error fetching forecast data", error);
      }
    }

    if (lat !== null && lng !== null) {
      fetchForecastData();
    }
  }, [lat, lng]);

  useEffect(() => {
    async function fetchStationsData() {
      const fetchedData = await Promise.all(
        geojsonFeatures.map(async (feature) => {
          const { coordinates } = feature.geometry;
          const { ADM1_ES } = feature.properties;
          const [lng, lat] = coordinates;
          try {
            const response = await axios.get(
              `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lng}&appid=a4727eca53146e091e3d4b5789e6398c`
            );
            const data = response.data.list[0];
            return {
              name: ADM1_ES,
              location: `Lat: ${lat}, Lng: ${lng}`,
              aqi: data.main.aqi,
              co: data.components.co,
              no: data.components.no,
              no2: data.components.no2,
              o3: data.components.o3,
              so2: data.components.so2,
              pm2_5: data.components.pm2_5,
              pm10: data.components.pm10,
              nh3: data.components.nh3,
            };
          } catch (error) {
            console.error("Error fetching station data", error);
            return null;
          }
        })
      );
      setStationsData(fetchedData.filter((data) => data !== null));
    }

    if (geojsonFeatures.length > 0) {
      fetchStationsData();
    }
  }, [geojsonFeatures]);

  useEffect(() => {
    const fetchGeoJSON = async () => {
      try {
        const response = await fetch("/stations.geojson");
        const geojson = await response.json();
        setGeojsonFeatures(geojson.features);
      } catch (error) {
        console.error("Error fetching GeoJSON:", error);
      }
    };

    fetchGeoJSON();
  }, []);

  return (
    <div className="flex flex-col justify-center bg-white w-full">
      <div className="flex flex-col items-center pb-5 w-full bg-slate-50 max-md:max-w-full">
       
        <div className="flex flex-col px-4 mt-9 w-full max-w-[960px] max-md:max-w-full">
          <div className="self-start text-3xl font-bold text-neutral-900">
            Air Quality Dashboard
          </div>
          <div className="self-start mt-3 text-sm leading-5 text-slate-500">
            {locationName}
          </div>
          <div className="flex gap-3 mt-8 max-md:flex-wrap">
            <Card
              icon={getIcon(getStatus(pollutionData.aqi, "aqi"))}
              value={pollutionData.aqi}
              unit=""
              label="Air Quality Index"
              status={getStatus(pollutionData.aqi, "aqi")}
            />
            <Card
              icon={getIcon(getStatus(pollutionData.co, "co"))}
              value={pollutionData.co}
              unit="µg/m³"
              label="CO"
              status={getStatus(pollutionData.co, "co")}
            />
                 <Card
              icon={getIcon(getStatus(pollutionData.no, "no"))}
              value={pollutionData.no}
              unit="µg/m³"
              label="NO"
              status={getStatus(pollutionData.no, "no")}
            />
            <Card
              icon={getIcon(getStatus(pollutionData.no2, "no2"))}
              value={pollutionData.no2}
              unit="µg/m³"
              label="NO₂"
              status={getStatus(pollutionData.no2, "no2")}
            />
            </div>
             <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <Card
              icon={getIcon(getStatus(pollutionData.o3, "o3"))}
              value={pollutionData.o3}
              unit="µg/m³"
              label="O3"
              status={getStatus(pollutionData.o3, "o3")}
            />
               <Card
              icon={getIcon(getStatus(pollutionData.so2, "so2"))}
              value={pollutionData.so2}
              unit="µg/m³"
              label="SO₂"
              status={getStatus(pollutionData.no2, "so2")}
            />
            <Card
              icon={getIcon(getStatus(pollutionData.pm2_5, "pm2_5"))}
              value={pollutionData.pm2_5}
              unit="µg/m³"
              label="PM2.5"
              status={getStatus(pollutionData.pm2_5, "pm2_5")}
            />
            <Card
              icon={getIcon(getStatus(pollutionData.pm10, "pm10"))}
              value={pollutionData.pm10}
              unit="µg/m³"
              label="PM10"
              status={getStatus(pollutionData.pm10, "pm10")}
            />
             <Card
              icon={getIcon(getStatus(pollutionData.nh3, "nh3"))}
              value={pollutionData.nh3}
              unit="µg/m³"
              label="NH3"
              status={getStatus(pollutionData.nh3, "nh3")}
            />


          </div>
          <div className="flex flex-col mt-5">
            <Map onPointClick={handlePointClick} stationsData={stationsData}   />
          </div>
          <div className="mt-8 text-2xl font-bold leading-7 text-neutral-900 max-md:max-w-full">
            5 days forecast
          </div>
          <div className="grid grid-cols-2 gap-4 mt-5 max-md:grid-cols-1">
            <GCard title="Ozone(ppb) " graphData={forecastData.o3Data} />
            <GCard title="PM10(µg/m³)" graphData={forecastData.pm10Data} />
            <GCard title="PM2.5(µg/m³) Forecast" graphData={forecastData.pm25Data} />
          </div>
          <div className="mt-4 text-lg font-bold leading-6 text-neutral-900 max-md:max-w-full">
            Air Quality Monitoring Stations Overview
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-slate-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Station Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Latest Readings
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {stationsData.map((station, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {station.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {station.location}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      AQI: {station.aqi}, CO: {station.co} µg/m³,NO: {station.no}, NO₂: {station.no2} µg/m³, O3: {station.o3} µg/m³,SO₂: {station.so2} µg/m³, PM2.5: {station.pm2_5} µg/m³, PM10: {station.pm10} µg/m³,NH3: {station.nh3} µg/m³
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Air;
