import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import ComboBarChart from "./ComboBarChart";

// LoadingComponent to display loading states
const LoadingComponent = () => (
  <div className="border border-blue-300 shadow rounded-md p-4 w-full mx-auto">
    <div className="animate-pulse flex flex-col space-y-4">
      <div className="h-64 bg-gray-300 rounded-md"></div>
      <div className="flex-1 space-y-4">
        <div className="h-2 bg-slate-700 rounded"></div>
        <div className="h-2 bg-slate-700 rounded col-span-1"></div>
        <div className="h-2 bg-slate-700 rounded col-span-2"></div>
      </div>
    </div>
  </div>
);

function Climate() {
  const [co2EmissionsData, setCo2EmissionsData] = useState(null);
  const [newEmissionsData, setNewEmissionsData] = useState(null);

  // Fetch data using async/await for better readability
  const fetchData = async () => {
    try {
      const response1 = await fetch(
        "https://www.globalforestwatch.org/api/data/dataset/gadm__tcl__iso_change/v20240404/query/?sql=SELECT%20iso%2C%20umd_tree_cover_loss__year%2C%20SUM(umd_tree_cover_loss__ha)%20AS%20umd_tree_cover_loss__ha%2C%20SUM(%22gfw_gross_emissions_co2e_all_gases__Mg%22)%20AS%20%22gfw_gross_emissions_co2e_all_gases__Mg%22%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20AND%20umd_tree_cover_density_2000__threshold%20%3D%2030%20%20GROUP%20BY%20umd_tree_cover_loss__year%2C%20iso%20ORDER%20BY%20umd_tree_cover_loss__year%2C%20iso"
      );
      const data1 = await response1.json();
      setCo2EmissionsData(data1.data);

      const response2 = await fetch(
        "https://www.globalforestwatch.org/api/data/dataset/gadm__tcl__iso_change/v20240404/query/?sql=SELECT%20tsc_tree_cover_loss_drivers__driver%2C%20umd_tree_cover_loss__year%2C%20SUM(%22gfw_gross_emissions_co2e_all_gases__Mg%22)%20AS%20%22gfw_gross_emissions_co2e_all_gases__Mg%22%2C%20SUM(%22gfw_full_extent_gross_emissions_non_CO2__Mg_CO2e%22)%20AS%20%22gfw_gross_emissions_co2e_non_co2__Mg%22%2C%20SUM(%22gfw_full_extent_gross_emissions_CO2_only__Mg_CO2%22)%20AS%20%22gfw_gross_emissions_co2e_co2_only__Mg%22%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20AND%20umd_tree_cover_density_2000__threshold%20%3D%2030%20AND%20tsc_tree_cover_loss_drivers__driver%20IS%20NOT%20NULL%20%20GROUP%20BY%20tsc_tree_cover_loss_drivers__driver%2C%20umd_tree_cover_loss__year"
      );
      const data2 = await response2.json();
      setNewEmissionsData(data2.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
<div className="p-4 space-y-10">
<div className="bg-white shadow-md rounded-lg p-6">


      <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-green-500 pb-2">
        Gross CO2 Emissions in Guatemala
      </h1>
      <span className="block mb-4 text-gray-700 text-lg">
      Between 2001 and 2023, an average of 36.1 Mt per year was released into the atmosphere as a result of tree cover loss in Guatemala. In total, 830 Mt of CO₂e was emitted in this period.
        </span>
      {co2EmissionsData ? (
        <BarChart
          data={co2EmissionsData}
          xLabel="Year"
          yLabel="CO2 Emissions (Mg)"
          xDataKey="umd_tree_cover_loss__year"
          yDataKey="gfw_gross_emissions_co2e_all_gases__Mg"
          barColor="green"
        />
      ) : (
        <LoadingComponent />
      )}
  </div>
  <div className="bg-white shadow-md rounded-lg p-6">
      <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
      Forest-related greenhouse gas emissions in Guatemala by dominant driver
      </h1>
      <span className="block mb-4 text-gray-700 text-lg">
      In Guatemala from 2001 to 2023, an average of 90.2 MtCO₂e occurred in areas where the dominant drivers of loss resulted in deforestation
      .
        </span>
      {newEmissionsData ? (
   <ComboBarChart
   data={newEmissionsData}
   xLabel="Year"
   yLabel="CO2 Emissions (Mg)"
   xDataKey="umd_tree_cover_loss__year"
   yDataKey="gfw_gross_emissions_co2e_all_gases__Mg"
   barColor="orange"
   groupByKey="tsc_tree_cover_loss_drivers__driver"
 />
 
      ) : (
        <LoadingComponent />
      )}
</div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Carbon stock in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          Guatemala has a total carbon store of 1.28 Gt, with most of the carbon stored in biomass.
        </span>
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Soil carbon", value: 529157984.4284646 },
              { label: "Above ground carbon", value: 597788318.4934815 },
              { label: "Below ground carbon", value: 154694751.99080458 },
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-blue-500"></div>
              <span>Soil carbon: 529 Mt</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-red-500"></div>
              <span>Above ground carbon: 598 Mt</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-500"></div>
              <span>Below ground carbon: 155 Mt</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Climate;
