import React, { useEffect, useState } from 'react';
import { FaTree, FaWater, FaCity, FaSun, FaSeedling, FaLeaf } from 'react-icons/fa';
import PieChart from "./PieChart"; // Assuming you have a PieChart component
import BarChartPercentage from "./BarChartPercentage";
function LandCover() {
  const [landCoverData, setLandCoverData] = useState([]);
  const [treeCoverDensityData, setTreeCoverDensityData] = useState(null);
  useEffect(() => {
    const fetchLandCoverData = async () => {
      try {
        const response = await fetch('https://www.globalforestwatch.org/api/data/dataset/gadm__tcl__iso_summary/v20230502/query/?sql=SELECT%20iso%2C%20umd_global_land_cover__ipcc_class%2C%20SUM(wri_tropical_tree_cover_extent__ha)%20AS%20wri_tropical_tree_cover_extent__ha%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20%20AND%20wri_tropical_tree_cover__decile%20%3E%3D%2030%20AND%20umd_global_land_cover__ipcc_class%20IS%20NOT%20NULL%20GROUP%20BY%20iso%2C%20umd_global_land_cover__ipcc_class%20ORDER%20BY%20iso%2C%20umd_global_land_cover__ipcc_class');
        const data = await response.json();
        const totalHa = data.data.reduce((acc, item) => acc + item.wri_tropical_tree_cover_extent__ha, 0);

        const convertedData = data.data.map(item => {
          const areaInKha = item.wri_tropical_tree_cover_extent__ha / 1000;
          const areaInMha = areaInKha / 1000;
          const percentage = (item.wri_tropical_tree_cover_extent__ha / totalHa) * 100;

          return {
            class: item.umd_global_land_cover__ipcc_class,
            areaInKha,
            areaInMha,
            percentage,
          };
        });

        setLandCoverData(convertedData);
      } catch (error) {
        console.error('Error fetching the land cover data:', error);
      }
    };

    fetchLandCoverData();
  }, []);

  useEffect(() => {
 
    // Fetch Tree Cover Density data
    fetch(
      "https://www.globalforestwatch.org/api/data/dataset/gadm__tcl__iso_summary/v20230502/query/?sql=SELECT%20iso%2C%20wri_tropical_tree_cover__decile%2C%20%20SUM(wri_tropical_tree_cover_extent__ha)%20AS%20wri_tropical_tree_cover_extent__ha%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20%20AND%20wri_tropical_tree_cover__decile%20%3E%3D%200%20GROUP%20BY%20iso%2C%20wri_tropical_tree_cover__decile%20ORDER%20BY%20iso%2C%20wri_tropical_tree_cover__decile"
    )
      .then((response) => response.json())
      .then((data) => {
        setTreeCoverDensityData(data.data);
      });
  }, []);

  const iconMapping = {
    Forest: <FaTree className="text-green-500 text-3xl" />,
    Grassland: <FaSeedling className="text-green-500 text-3xl" />,
    Settlements: <FaCity className="text-gray-500 text-3xl" />,
    Wetlands: <FaWater className="text-blue-500 text-3xl" />,
    Cropland: <FaSun className="text-yellow-500 text-3xl" />,
    Other: <FaLeaf className="text-green-700 text-3xl" />,
  };

  return (
    <div className="p-4 space-y-10">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Tree Cover by Land Cover Class in Guatemala (2020)
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          In 2020, Guatemala had &gt;30% tree cover in the following land cover classes:
        </span>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-28">
          {landCoverData.map((item) => (
            <div key={item.class} className="flex items-center space-x-3">
              {iconMapping[item.class]}
              <div>
                <span className="block font-bold text-2xl">{item.class}</span>
                <span className="block text-gray-700">{item.percentage.toFixed(2)}% ({item.areaInMha.toFixed(2)} Mha)</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Carbon stock section */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Carbon stock in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          Guatemala has a total carbon store of 1.28 Gt, with most of the carbon stored in biomass.
        </span>
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Soil carbon", value: 529157984.4284646 },
              { label: "Above ground carbon", value: 597788318.4934815 },
              { label: "Below ground carbon", value: 154694751.99080458 },
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-blue-500"></div>
              <span>Soil carbon: 529 Mt</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-red-500"></div>
              <span>Above ground carbon: 598 Mt</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-500"></div>
              <span>Below ground carbon: 155 Mt</span>
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Tree Cover Density in Guatemala
        </h1>
        <span>In 2020, Guatemala had 8.49 Mha of land above 10% tree cover, extending over 79.1% of its land area.</span>
        {treeCoverDensityData ? (
          <BarChartPercentage
            data={treeCoverDensityData}
            xLabel="Tree cover (%)"
            yLabel="Land area (in hectares)"
            xDataKey="wri_tropical_tree_cover__decile"
            yDataKey="wri_tropical_tree_cover_extent__ha"
            barColor="teal"
          />
        ) : (
          <div className="border border-blue-300 shadow rounded-md p-4 w-full mx-auto">
            <div className="animate-pulse flex flex-col space-y-4">
              <div className="h-64 bg-gray-300 rounded-md"></div>
              <div className="flex-1 space-y-4">
                <div className="h-2 bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Intact Forest Card with Pie Chart */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Intact Forest in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          As of 2000, <strong>7.3%</strong> of Guatemala's tree cover was <strong>intact forest</strong>.
        </span>
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Intact Forest", value: 565000 }, // Example value
              { label: "Other Tree Cover", value: 7130000 }, // Example value
              { label: "Non-Forest", value: 3220000 }, // Example value
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-700"></div>
              <span>Intact Forest: 565 kha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-400"></div>
              <span>Other Tree Cover: 7.13 Mha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-yellow-200"></div>
              <span>Non-Forest: 3.22 Mha</span>
            </span>
          </div>
        </div>
      </div>

      {/* Primary Forest Card with Pie Chart */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Primary Forest in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          As of 2001, <strong>34%</strong> of Guatemala's total tree cover was <strong>primary forest</strong>.
        </span>
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Primary Forest", value: 2580000 }, // Example value
              { label: "Other Tree Cover", value: 5110000 }, // Example value
              { label: "Non-Forest", value: 3220000 }, // Example value
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-700"></div>
              <span>Primary Forest: 2.58 Mha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-400"></div>
              <span>Other Tree Cover: 5.11 Mha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-yellow-200"></div>
              <span>Non-Forest: 3.22 Mha</span>
            </span>
          </div>
        </div>
        <p className="text-gray-500 text-sm mt-4">2000 tree cover extent | &gt;30% tree canopy</p>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          FAO Forest Cover in Guatemala (2020)
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          According to the FAO, in 2020, 33% (3.53 Mha) of Guatemala was covered by forest. 19% of that forest was classified as primary forest.
        </span>
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Primary Forest", value: 658 }, // Example value in kha
              { label: "Planted Forest", value: 152 }, // Example value in kha
              { label: "Other Tree Cover", value: 2720 }, // Example value in kha
              { label: "Non-Forest", value: 7190 }, // Example value in kha
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-700"></div>
              <span>Primary Forest: 658 kha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-400"></div>
              <span>Planted Forest: 152 kha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-yellow-200"></div>
              <span>Other Tree Cover: 2.72 Mha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-gray-300"></div>
              <span>Non-Forest: 7.19 Mha</span>
            </span>
          </div>
        </div>
      </div>

      {/* Plantations in Guatemala */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Plantations in Guatemala (2020)
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          In Guatemala, oil palm represents the largest plantation area by type, spanning 159 kha and 1.5% of land area.
        </span>
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Oil palm", value: 159 }, // Example value in kha
              { label: "Wood fiber or timber", value: 39.1 }, // Example value in kha
              { label: "Unknown", value: 12.3 }, // Example value in kha
              { label: "Rubber", value: 10.8 }, // Example value in kha
              // Add other categories as needed
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-700"></div>
              <span>Oil palm: 159 kha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-gray-400"></div>
              <span>Wood fiber or timber: 39.1 kha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-gray-200"></div>
              <span>Unknown: 12.3 kha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-gray-500"></div>
              <span>Rubber: 10.8 kha</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandCover;
