import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

function PieChart({ data }) {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({ width: 450, height: 450 });

  useEffect(() => {
    // Function to update chart dimensions based on screen size
    const updateDimensions = () => {
      const { innerWidth: width } = window;
      if (width < 500) {
        setDimensions({ width: 300, height: 300 });
      } else {
        setDimensions({ width: 450, height: 450 });
      }
    };

    // Set dimensions on initial render
    updateDimensions();

    // Add event listener for window resize
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (data) {
      const { width, height } = dimensions;
      const margin = 40;
      const radius = Math.min(width, height) / 2 - margin;

      // Clear previous SVG content
      d3.select(ref.current).selectAll("*").remove();

      // Create SVG
      const svg = d3
        .select(ref.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2},${height / 2})`);

      // Create color scale
      const color = d3
        .scaleOrdinal()
        .domain(data.map(d => d.label))
        .range(d3.schemeCategory10);

      // Compute the position of each group on the pie
      const pie = d3.pie().value(d => d.value);
      const data_ready = pie(data);

      // Define the arc generator
      const arc = d3.arc().innerRadius(0).outerRadius(radius);

      // Build the pie chart
      svg
        .selectAll("path")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", d => color(d.data.label))
        .attr("stroke", "white")
        .style("stroke-width", "2px")
        .style("opacity", 0.7)
        .on("mouseover", function (event, d) {
          d3.select(this).style("opacity", 1);
          tooltip.style("visibility", "visible").text(`${d.data.label}: ${d.data.value}`);
        })
        .on("mousemove", function (event) {
          tooltip
            .style("top", event.pageY - 50 + "px")
            .style("left", event.pageX + "px");
        })
        .on("mouseout", function () {
          d3.select(this).style("opacity", 0.7);
          tooltip.style("visibility", "hidden");
        });

      // Add tooltip div
      const tooltip = d3
        .select("body")
        .append("div")
        .style("position", "absolute")
        .style("background", "#f4f4f4")
        .style("padding", "5px 10px")
        .style("border-radius", "5px")
        .style("box-shadow", "0px 0px 5px rgba(0,0,0,0.3)")
        .style("visibility", "hidden");
    }
  }, [data, dimensions]);

  return <div ref={ref}></div>;
}

export default PieChart;
