import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";
import PieChart from "./PieChart"; 
import ComboBarChart from "./ComboBarChart";


// LoadingComponent to display loading states
const LoadingComponent = () => (
  <div className="border border-blue-300 shadow rounded-md p-4 w-full mx-auto">
    <div className="animate-pulse flex flex-col space-y-4">
      <div className="h-64 bg-gray-300 rounded-md"></div>
      <div className="flex-1 space-y-4">
        <div className="h-2 bg-slate-700 rounded"></div>
        <div className="h-2 bg-slate-700 rounded col-span-1"></div>
        <div className="h-2 bg-slate-700 rounded col-span-2"></div>
      </div>
    </div>
  </div>
);

function ForestChange() {
  const [treeCoverLossData, setTreeCoverLossData] = useState(null);
  const [treeCoverDensityData, setTreeCoverDensityData] = useState(null);
  const [newEmissionsData, setNewEmissionsData] = useState(null);
console.log(newEmissionsData)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Tree Cover Loss and CO2 Emissions data
        const response1 = await fetch(
          "https://www.globalforestwatch.org/api/data/dataset/gadm__tcl__iso_change/v20240404/query/?sql=SELECT%20iso%2C%20umd_tree_cover_loss__year%2C%20SUM(umd_tree_cover_loss__ha)%20AS%20umd_tree_cover_loss__ha%2C%20SUM(%22gfw_gross_emissions_co2e_all_gases__Mg%22)%20AS%20%22gfw_gross_emissions_co2e_all_gases__Mg%22%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20AND%20umd_tree_cover_density_2000__threshold%20%3D%2030%20%20GROUP%20BY%20umd_tree_cover_loss__year%2C%20iso%20ORDER%20BY%20umd_tree_cover_loss__year%2C%20iso"
        );
        const data1 = await response1.json();
        setTreeCoverLossData(data1.data);
  
        // Fetch Tree Cover Density data
        const response2 = await fetch(
          "https://www.globalforestwatch.org/api/data/dataset/gadm__tcl__iso_summary/v20230502/query/?sql=SELECT%20iso%2C%20wri_tropical_tree_cover__decile%2C%20%20SUM(wri_tropical_tree_cover_extent__ha)%20AS%20wri_tropical_tree_cover_extent__ha%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20%20AND%20wri_tropical_tree_cover__decile%20%3E%3D%200%20GROUP%20BY%20iso%2C%20wri_tropical_tree_cover__decile%20ORDER%20BY%20iso%2C%20wri_tropical_tree_cover__decile"
        );
        const data2 = await response2.json();
        setTreeCoverDensityData(data2.data);
  
        // Fetch new emissions data
        const response3 = await fetch(
          "https://www.globalforestwatch.org/api/data/dataset/gadm__tcl__iso_change/v20240404/query/?sql=SELECT%20tsc_tree_cover_loss_drivers__driver%2C%20umd_tree_cover_loss__year%2C%20SUM(umd_tree_cover_loss__ha)%20AS%20umd_tree_cover_loss__ha%2C%20SUM(%22gfw_gross_emissions_co2e_all_gases__Mg%22)%20AS%20%22gfw_gross_emissions_co2e_all_gases__Mg%22%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20AND%20umd_tree_cover_density_2000__threshold%20%3D%2030%20AND%20tsc_tree_cover_loss_drivers__driver%20IS%20NOT%20NULL%20%20GROUP%20BY%20tsc_tree_cover_loss_drivers__driver%2C%20umd_tree_cover_loss__year"
        );
        const data3 = await response3.json();
        setNewEmissionsData(data3.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <div className="p-4  space-y-10">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-indigo-500 pb-2">
          Tree Cover Loss in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          From 2001 to 2023, Guatemala lost 1.75 Mha of tree cover, equivalent to a 23% decrease in tree cover since 2000, and 830 Mt of CO₂e emissions.
        </span>
        {treeCoverLossData ? (
          <BarChart
            data={treeCoverLossData}
            xLabel="Year"
            yLabel="Tree Cover Loss (ha)"
            xDataKey="umd_tree_cover_loss__year"
            yDataKey="umd_tree_cover_loss__ha"
            barColor="steelblue"
          />
        ) : (
          <div className="border border-blue-300 shadow rounded-md p-4 w-full mx-auto">
            <div className="animate-pulse flex flex-col space-y-4">
              <div className="h-64 bg-gray-300 rounded-md"></div>
              <div className="flex-1 space-y-4">
                <div className="h-2 bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
      <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
      Annual tree cover loss by dominant driver in Guatemala
      </h1>
      <span className="block mb-4 text-gray-700 text-lg">
      In Guatemala from 2001 to 2023, 12% of tree cover loss occurred in areas where the dominant drivers of loss resulted in deforestation
        </span>
      {newEmissionsData ? (
   <ComboBarChart
   data={newEmissionsData}
   xLabel="Year"
   yLabel="umd_tree_cover_loss__ha"
   xDataKey="umd_tree_cover_loss__year"
   yDataKey="umd_tree_cover_loss__ha"
   barColor="orange"
   groupByKey="tsc_tree_cover_loss_drivers__driver"
 />
 
      ) : (
        <LoadingComponent />
      )}
</div>


      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Integrated Deforestation Alerts in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          There were 209,690 deforestation alerts reported in Guatemala between 5th of August 2024 and 12th of August 2024, covering a total of 2.49 kha. Of these, 
          <b>{((178 / 209690) * 100).toFixed(1)}%</b> were detected by multiple alert systems with the highest confidence, 
          <b>{((170 / 209690) * 100).toFixed(1)}%</b> were high confidence detections by a single system, and 
          <b>{((209342 / 209690) * 100).toFixed(1)}%</b> were detections by a single alert system.
        </span>
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Highest confidence detection by multiple alert systems", value: 2.1111047854483647 },
              { label: "High confidence detection by a single alert system", value: 2.0171482318003604 },
              { label: "Detection by a single alert system", value: 2481.781924513052 },
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-red-500"></div>
              <span>Highest confidence detection by multiple alert systems: 178 alerts</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-orange-500"></div>
              <span>High confidence detection by a single alert system: 170 alerts</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-blue-500"></div>
              <span>Detection by a single alert system: 209,342 alerts</span>
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
          Components of Net Change in Tree Cover in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          From 2000 to 2020, Guatemala experienced a net change of -470 kha (-6.0%) in tree cover.
        </span>

        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Stable", value: 5808934.441 },
              { label: "Loss", value: 760663.6792 },
              { label: "Gain", value: 291032.6654 },
              { label: "Disturb", value: 1205652.555 },
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-blue-500"></div>
              <span>Stable: 5.81Mha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-red-500"></div>
              <span>Loss: 0.76Mha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-green-500"></div>
              <span>Gain: 0.29Mha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-orange-500"></div>
              <span>Disturb: 1.21Mha</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForestChange;
