import React, { useState } from "react";
import { FaFire, FaCloudSun } from "react-icons/fa";
import { GiCircleForest } from "react-icons/gi";
import ForestChange from "../Components/forestwatch/ForestChange";
import Climate from "./forestwatch/Climate";
import Fires from "./forestwatch/Fires";
import LandCover from "./forestwatch/LandCover";

function ForestWatch() {
  const [activeTab, setActiveTab] = useState("forestchange");

  return (
    <div className="flex flex-col px-4 mt-9 w-full max-w-[960px] mx-auto">
      <div className="text-2xl leading-5 text-gray-600 mb-6">
        <h1>Forest Watch</h1>
      </div>
      <div className="flex space-x-4 mb-6">
        <button
          className={`flex items-center px-4 py-2 rounded-md transition ${
            activeTab === "forestchange" ? "bg-gray-700 text-white" : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setActiveTab("forestchange")}
        >
          <GiCircleForest className="mr-2" /> Forest Change
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-md transition ${
            activeTab === "fires" ? "bg-gray-700 text-white" : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setActiveTab("fires")}
        >
          <FaFire className="mr-2" /> Fires
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-md transition ${
            activeTab === "climate" ? "bg-gray-700 text-white" : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setActiveTab("climate")}
        >
          <FaCloudSun className="mr-2" /> Climate
        </button>
        <button
          className={`flex items-center px-4 py-2 rounded-md transition ${
            activeTab === "landcover" ? "bg-gray-700 text-white" : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setActiveTab("landcover")}
        >
          <GiCircleForest className="mr-2" /> Land Cover
        </button>
      </div>
      <div className="flex flex-col w-full">
        {activeTab === "forestchange" && <ForestChange />}
        {activeTab === "fires" && <Fires />} 
        {activeTab === "climate" && <Climate />}
        {activeTab === "landcover" && <LandCover />}
      </div>
    </div>
  );
}

export default ForestWatch;
