import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa'; // Import the info icon from react-icons

function Legend({ legendColors }) {
  const [visibleDescription, setVisibleDescription] = useState(null);

  // Function to toggle the description visibility
  const toggleDescription = (class_no) => {
    if (visibleDescription === class_no) {
      setVisibleDescription(null); // Hide description if it's already visible
    } else {
      setVisibleDescription(class_no); // Show description for the selected item
    }
  };

  return (
    <div className="absolute bottom-4 right-4 p-4 bg-white shadow-lg rounded-lg z-10 max-w-xs">
      <h1 className="text-xl font-bold text-gray-800 mb-4 border-b-2 border-gray-300 pb-2">
        NDVI Legend
      </h1>
      {legendColors && (
        <div className="space-y-4 max-h-80 overflow-y-auto"> {/* Set a max height and enable scrolling */}
          {legendColors
            .sort((a, b) => a.range[0] - b.range[0])
            .map(({ class_no, range, description, color }) => (
              <div
                key={class_no}
                className="flex items-start space-x-4 p-3 border border-gray-200 rounded-lg"
              >
                <div
                  className="w-6 h-6 rounded-full"
                  style={{ backgroundColor: color }}
                />
                <div className="flex-1">
                  <div className="text-sm font-semibold text-gray-700">
                    {range[0].toFixed(2)} to {range[1].toFixed(2)}
                  </div>
                  <div className="flex items-center mt-1">
                    <FaInfoCircle
                      className="text-blue-500 cursor-pointer mr-2"
                      title={description} // Tooltip using title attribute
                      onClick={() => toggleDescription(class_no)}
                    />
                    {visibleDescription === class_no && (
                      <div className="text-xs text-gray-600 bg-gray-100 p-2 rounded-lg mt-1">
                        {description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default Legend;
