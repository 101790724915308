import React, { useEffect, useRef } from 'react';
import maplibre from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

const SpeciesMap = ({ coordinates }) => {
  const mapContainer = useRef(null);
 console.log(coordinates)
  useEffect(() => {
    if (mapContainer.current) {
      const map = new maplibre.Map({
        container: mapContainer.current,
        style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=Otbh9YhFMbwux7HyoffB',
        center: coordinates,
        zoom: 10, // Initial zoom level
        pitch: 20, // Initial pitch
      });

      // Optional: Add a marker at the coordinates
      new maplibre.Marker()
        .setLngLat(coordinates)
        .addTo(map);

      return () => map.remove();
    }
  }, [coordinates]);

  return <div ref={mapContainer} className="w-full h-96 relative" />;
};

export default SpeciesMap;
