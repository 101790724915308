import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaInfoCircle, FaFilter } from 'react-icons/fa';

function Species() {
  const [species, setSpecies] = useState(null); // Start with null for the skeleton loader
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('All');
  const speciesPerPage = 6; // Number of species to show per page
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch species data from the API
    fetch('https://api.terra-metrics.com/api/species/')
      .then(response => response.json())
      .then(data => setSpecies(data))
      .catch(error => console.error('Error fetching species data:', error));
  }, []);

  // Filter species based on search query and category filter
  const filteredSpecies = species ? species.filter(speciesItem => {
    return (
      (speciesItem.name.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (categoryFilter === 'All' || speciesItem.category === categoryFilter)
    );
  }) : [];

  // Calculate current species
  const indexOfLastSpecies = currentPage * speciesPerPage;
  const indexOfFirstSpecies = indexOfLastSpecies - speciesPerPage;
  const currentSpecies = filteredSpecies.slice(indexOfFirstSpecies, indexOfLastSpecies);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Navigate to detail page
  const handleViewDetails = (id) => {
    navigate('/detailspeciesview', { state: { id } });
  };

  return (
    <div className="flex flex-col justify-center bg-white w-full">
      <div className="flex flex-col items-center pb-5 w-full bg-slate-50 max-md:max-w-full">
        <div className="flex flex-col px-4 mt-9 w-full max-w-[960px] max-md:max-w-full">
          <div className="self-start text-3xl font-bold text-neutral-900">
            Endangered Species Dashboard
          </div>
          <div className="self-start mt-3 text-sm leading-5 text-slate-500">
            Guatemala
          </div>
          <div className="mt-5 flex items-center">
            <div className="relative flex items-center w-full">
              <input
                type="text"
                className="w-full p-2 border rounded-md"
                placeholder="Search species..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="absolute right-3 text-gray-400" />
            </div>
            <div className="relative flex items-center ml-4">
              <FaFilter className="absolute left-3 text-gray-400" />
              <select
                className="p-2 pl-8 border rounded-md bg-white text-gray-800"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
              >
                <option value="All">All</option>
                <option value="Mammal">Mammal</option>
                <option value="Bird">Bird</option>
                <option value="Reptile">Reptile</option>
              </select>
            </div>
          </div>
          <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {species ? (
              currentSpecies.map((speciesItem) => (
                <div key={speciesItem.id} className="bg-white shadow-md rounded-lg p-5 transition-transform transform hover:scale-105">
                  <div className="flex flex-col items-center">
                    <img src={speciesItem.image} alt={speciesItem.name} className="h-40 w-full object-cover mb-4" />
                    <div className="text-lg font-bold mb-2 text-gray-800">{speciesItem.name}</div>
                    <button
                      onClick={() => handleViewDetails(speciesItem.id)}
                      className="mt-2 bg-gray-700 text-white py-2 px-4 rounded-full hover:bg-gray-500 flex items-center"
                    >
                      <FaInfoCircle className="mr-2 " /> View Details
                    </button>
                  </div>
                </div>
              ))
            ) : (
              Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="border border-gray-300 shadow rounded-md p-4 max-w-sm w-full mx-auto bg-white animate-pulse">
                  <div className="animate-pulse flex flex-col space-y-4">
                    <div className="h-40 bg-gray-300 rounded"></div>
                    <div className="flex-1 space-y-4">
                      <div className="h-4 bg-gray-300 rounded"></div>
                      <div className="h-4 bg-gray-300 rounded"></div>
                      <div className="h-4 bg-gray-300 rounded"></div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="mt-5 flex justify-center">
            <Pagination
              speciesPerPage={speciesPerPage}
              totalSpecies={filteredSpecies.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Pagination({ speciesPerPage, totalSpecies, paginate, currentPage }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalSpecies / speciesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="flex list-none">
        {pageNumbers.map((number) => (
          <li key={number} className="mx-1">
            <button
              onClick={() => paginate(number)}
              className={`py-2 px-4 rounded-full ${currentPage === number ? 'bg-gray-700 text-white' : 'bg-gray-400 text-gray-800'}`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Species;
