import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import maplibregl, { NavigationControl, FullscreenControl } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { FiMapPin } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';

function ObservationsComponent() {
  const [observations, setObservations] = useState([]);
  const [loadingObservations, setLoadingObservations] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const markersRef = useRef([]);

  // Fetch observations with pagination
  const fetchObservations = async (page = 1) => {
    const baseUrl = 'https://api.terra-metrics.com/api/observations/';
    try {
      setLoadingMore(true);
      const response = await axios.get(baseUrl, {
        params: { page },
        timeout: 30000,
      });
      const data = response.data.results;
      setObservations((prev) => (page === 1 ? data : [...prev, ...data]));
      setLoadingObservations(false);
      setLoadingMore(false);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoadingMore(false);
    }
  };

  // Fetch search results
  const fetchSearchResults = async (query) => {
    const searchUrl = `https://api.terra-metrics.com/api/observations/search/`;
    try {
      setSearching(true);
      const response = await axios.get(searchUrl, {
        params: { search: query },
        timeout: 30000,
      });
      setSearchResults(response.data);
      setSearching(false);
    } catch (error) {
      console.error('Error fetching search data:', error);
      setSearching(false);
      setObservations([]);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchObservations(1); // Fetch page 1 on load
  }, []);

  // Map initialization and marker setup
  useEffect(() => {
    if ((observations.length > 0 || searchResults.length > 0) && mapContainerRef.current) {
      mapRef.current = new maplibregl.Map({
        container: mapContainerRef.current,
        style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=Otbh9YhFMbwux7HyoffB',
        center: [-89.8872090936, 16.9220233094],
        zoom: 6,
      });

      mapRef.current.addControl(new NavigationControl(), 'top-right');
      mapRef.current.addControl(new FullscreenControl(), 'bottom-right');

      const dataToDisplay = searchQuery ? searchResults : observations;

      dataToDisplay.forEach((observation, index) => {
        if (observation.latitude && observation.longitude) {
          const coordinates = [observation.longitude, observation.latitude];

          const marker = new maplibregl.Marker().setLngLat(coordinates).addTo(mapRef.current);

          const popupContent = `
            <div class="max-w-xs p-2 rounded">
              ${observation.photo_url ? `<img src="${observation.photo_url}" alt="${observation.name}" class="w-full h-32 object-cover rounded-md"/>` : ''}
              <h2 class="text-xl font-bold text-gray-800 mb-2">${observation.name || 'Unknown species'}</h2>
            </div>
          `;

          const popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent);
          marker.setPopup(popup);
          markersRef.current[index] = marker;
        }
      });

      return () => mapRef.current.remove();
    }
  }, [observations, searchResults]);

  // Handle zoom on hover
  const handleHover = (latitude, longitude) => {
    if (latitude && longitude && mapRef.current) {
      mapRef.current.flyTo({
        center: [longitude, latitude],
        zoom: 18,
      });
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const query = event.target.elements.search.value;
    setSearchQuery(query);
    fetchSearchResults(query);
  };

  // Pagination functions
  const handleNextPage = () => {
    fetchObservations(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      fetchObservations(currentPage - 1);
    }
  };

  return (
    <div className="flex flex-col bg-white w-full">
      <div className="flex flex-col items-center pb-5 w-full bg-slate-50">
        <div className="flex flex-col px-4 mt-9 w-full max-w-[960px]">
          <div className="self-start text-3xl font-bold text-neutral-900">Observations Dashboard</div>
          <div className="self-start mt-3 text-sm leading-5 text-slate-500">Guatemala</div>
          <form onSubmit={handleSearch} className="flex flex-col md:flex-row gap-2 mb-4 mt-5">
            <input
              type="text"
              name="search"
              placeholder="Search observations..."
              className="p-2 border rounded flex-1"
            />
            <button
              type="submit"
              className="bg-gray-700 hover:bg-gray-500 text-white px-4 py-2 rounded flex-shrink-0"
            >
              <FaSearch className="inline-block mr-2" />
              Search
            </button>
          </form>
          <div className="flex flex-col md:flex-row gap-4 mt-6 w-full">
            <div
              className="md:w-1/4 h-[300px] md:h-[600px] overflow-y-auto p-2 bg-gray-50"
            >
              {searching ? (
                <div className="text-center p-4">Searching...</div>
              ) : searchResults.length > 0 ? (
                searchResults.map((observation, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-md rounded-lg p-2 mb-2 cursor-pointer"
                    onMouseEnter={() => handleHover(observation.latitude, observation.longitude)}
                  >
                    {observation.photo_url && (
                      <img
                        src={observation.photo_url}
                        alt={observation.name}
                        className="w-full h-32 object-cover rounded-md"
                      />
                    )}
                    <h2 className="text-md font-bold text-gray-800 mt-1">{observation.name || 'Unknown species'}</h2>
                  </div>
                ))
              ) : (
                observations.map((observation, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-md rounded-lg p-2 mb-2 cursor-pointer"
                    onMouseEnter={() => handleHover(observation.latitude, observation.longitude)}
                  >
                    {observation.photo_url && (
                      <img
                        src={observation.photo_url}
                        alt={observation.name}
                        className="w-full h-32 object-cover rounded-md"
                      />
                    )}
                    <h2 className="text-md font-bold text-gray-800 mt-1">{observation.name || 'Unknown species'}</h2>
                  </div>
                ))
              )}
              <div className="flex justify-between mt-4">
                <button onClick={handlePrevPage} disabled={currentPage === 1} className="bg-gray-700 text-white px-4 py-2 rounded">
                  Previous
                </button>
                <button onClick={handleNextPage} className="bg-gray-700 text-white px-4 py-2 rounded">
                  Next
                </button>
              </div>
            </div>
            <div
              ref={mapContainerRef}
              className="md:w-3/4 h-[300px] md:h-[600px] bg-gray-50"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ObservationsComponent;
