import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function LineChart({ data, maxCount }) {
  const svgRef = useRef();

  useEffect(() => {
    if (data.length > 0) {
      const margin = { top: 20, right: 30, bottom: 40, left: 40 };
      
      // Set dimensions based on the container size
      const containerWidth = svgRef.current.parentElement.clientWidth;
      const width = containerWidth - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom;

      // Clear previous SVG content if any
      d3.select(svgRef.current).selectAll('*').remove();

      // Create an SVG container with viewBox for responsiveness
      const svg = d3
        .select(svgRef.current)
        .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // Create x-scale for weekly intervals
      const x = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => d.date))
        .range([0, width]);

      // Create y-scale with actual alert values
      const y = d3
        .scaleLinear()
        .domain([0, maxCount])
        .nice()
        .range([height, 0]);

      // Create line generator
      const line = d3
        .line()
        .x((d) => x(d.date))
        .y((d) => y(d.count))
        .curve(d3.curveMonotoneX);

      // Add x-axis with monthly ticks
      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x)
          .ticks(d3.timeMonth.every(1))
          .tickFormat(d3.timeFormat('%b %Y'))
        );

      // Add y-axis with actual alert values
      svg.append('g').call(d3.axisLeft(y));

      // Add the line
      svg
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', 'orange')
        .attr('stroke-width', 2)
        .attr('d', line);

      // Add horizontal dashed lines for y-axis intervals
      svg.selectAll('.dashed-line')
        .data(y.ticks(5))
        .enter()
        .append('line')
        .attr('class', 'dashed-line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', (d) => y(d))
        .attr('y2', (d) => y(d))
        .attr('stroke', '#ccc')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '4,4');

      // Add points to the line and tooltip
      const tooltip = d3.select('body').append('div').attr('class', 'absolute bg-white p-2 border rounded shadow-lg text-xs').style('opacity', 0);

      svg
        .selectAll('.dot')
        .data(data)
        .enter()
        .append('circle')
        .attr('class', 'dot')
        .attr('cx', (d) => x(d.date))
        .attr('cy', (d) => y(d.count))
        .attr('r', 4)
        .attr('fill', 'orange')
        .on('mouseover', (event, d) => {
          tooltip
            .transition()
            .duration(200)
            .style('opacity', 0.9);
          tooltip
            .html(`Date: ${d3.timeFormat('%b %d, %Y')(d.date)}<br/>Alerts: ${d.count}`)
            .style('left', `${event.pageX + 5}px`)
            .style('top', `${event.pageY - 28}px`);
        })
        .on('mouseout', () => {
          tooltip.transition().duration(500).style('opacity', 0);
        });
    }
  }, [data, maxCount]);

  return (
    <div className="w-full max-w-4xl mx-auto p-4 bg-white border border-gray-300 rounded-lg shadow-md">
      <div className="h-96">
        <svg ref={svgRef} className="w-full h-full"></svg>
      </div>
    </div>
  );
}

export default LineChart;
