import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaMapMarkedAlt, FaChartLine } from "react-icons/fa"; // Import icons
import ForestMap from "./ForestMap";

function ProtectedArea() {
  const [zoomToArea, setZoomToArea] = useState(null);
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the list of protected areas from the API
    const fetchAreas = async () => {
      try {
        const response = await fetch("https://api.terra-metrics.com/api/protected_area/");
        const data = await response.json();
        setAreas(data);
      } catch (error) {
        console.error("Error fetching protected areas:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAreas();
  }, []);

  const handleZoom = (geojson) => {
    setZoomToArea(geojson);
  };

  const handleViewDetails = (area) => {
    navigate("/detailareaview", { state: { area } });
  };

  return (
    <div className="flex flex-col sm:flex-row w-full max-w-[1200px] mx-auto mt-9">
      {/* Side Panel */}
      <div className="w-full sm:w-1/4 bg-gray-100 border-r border-gray-200 overflow-y-auto max-h-screen p-4 mb-4 sm:mb-0">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Protected Areas</h2>
        {loading ? (
          <div className="w-full flex flex-col gap-4">
            <div className="bg-gray-200 animate-pulse h-48 rounded-lg shadow-md mb-4"></div>
            <div className="h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
            <div className="h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
            <div className="h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
            <div className="h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
          </div>
        ) : (
          <div className="space-y-4">
            {areas.map((area, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-4 border border-gray-200">
                <h3 className="text-lg font-semibold text-gray-700">{area.name}</h3>
                <p className="text-sm text-gray-500">{area.designation}</p>
                <div className="mt-2 flex flex-col gap-2">
                  <button
                    className="flex items-center bg-gray-700 text-white px-4 py-2 rounded-md shadow-md hover:bg-gray-500 transition"
                    onClick={() => handleZoom(area.geojson)}
                  >
                    <FaMapMarkedAlt className="mr-2" /> Zoom to Area
                  </button>
                  <button
                    className="flex items-center bg-gray-700 text-white px-4 py-2 rounded-md shadow-md hover:bg-gray-500 transition"
                    onClick={() => handleViewDetails(area)}
                  >
                    <FaChartLine className="mr-2" /> Monitor
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Main Content */}
      <div className="w-full sm:w-3/4 p-4">
        <div className="text-2xl leading-5 text-gray-600 mb-6">
          <h1>Protected Areas Map</h1>
        </div>
        <div className="w-full h-[500px] relative">
          {loading ? (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <ForestMap areas={areas} zoomToArea={zoomToArea} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProtectedArea;
