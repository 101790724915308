import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const TimeSeriesGraph = ({ data }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (!data || data.length === 0) return;

    const svg = d3.select(svgRef.current);
    const container = svgRef.current.parentElement;
    const containerWidth = container.clientWidth;
    const containerHeight = 300; // Adjust height as needed

    const margin = { top: 20, right: 30, bottom: 60, left: 40 }; // Increased bottom margin for extra space for the date
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    svg.selectAll('*').remove();

    const g = svg
      .attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const parseDate = d3.timeParse('%Y-%m-%d');
    const formatMonthDay = d3.timeFormat('%b %d'); // Month and day only
    const formatYear = d3.timeFormat('%Y'); // Year only

    // Format the data
    data.forEach(d => {
      d.date = parseDate(d.date);
      d.value = +d.value;
    });

    // Extract the exact dates from the data
    const dates = data.map(d => d.date);

    // Use scalePoint to display only the dates from the data
    const x = d3.scalePoint()
      .domain(dates)
      .range([0, width])
      .padding(0.5); // Adjust padding if needed

    const y = d3.scaleLinear()
      .domain([d3.min(data, d => d.value) - 0.1, d3.max(data, d => d.value) + 0.1])
      .range([height, 0]);

    const line = d3.line()
      .x(d => x(d.date))
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);

    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#69b3a2')
      .attr('stroke-width', 2)
      .attr('d', line);

    // Create a div for the tooltip (HTML-based)
    const tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('background', 'black')
      .style('color', 'white')
      .style('padding', '8px')
      .style('border-radius', '8px')
      .style('font-size', '12px')
      .style('pointer-events', 'none')
      .style('visibility', 'hidden')
      .style('display', 'flex')
      .style('flex-direction', 'column')
      .style('text-align', 'center');

    g.selectAll('.dot')
      .data(data)
      .enter().append('circle')
      .attr('class', 'dot')
      .attr('cx', d => x(d.date))
      .attr('cy', d => y(d.value))
      .attr('r', 4)
      .attr('fill', '#69b3a2')
      .on('mouseover', function(event, d) {
        d3.select(this).attr('r', 6).attr('fill', '#2171b5'); // Highlight the point

        tooltip
          .style('visibility', 'visible')
          .html(`
            <div>${formatMonthDay(d.date)}</div>
            <div>${formatYear(d.date)}</div>
            <div>NDVI: ${d.value.toFixed(3)}</div>
          `);
      })
      .on('mousemove', function(event) {
        tooltip
          .style('top', `${event.pageY - 40}px`)
          .style('left', `${event.pageX + 10}px`);
      })
      .on('mouseout', function() {
        d3.select(this).attr('r', 4).attr('fill', '#69b3a2'); // Reset point style
        tooltip.style('visibility', 'hidden');
      });

    // Define the x-axis using the exact dates and format the date to show month/day on the first line and year on the second line
    const xAxis = d3.axisBottom(x)
      .tickFormat(d => formatMonthDay(d));

    const xAxisG = g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis);

    // Add the year below the month/day
    xAxisG.selectAll('text')
      .style('fill', 'black')
      .style('font-size', '0.7rem') // Smaller font size
      .style('text-anchor', 'middle')
      .attr('dy', null) // Reset the default dy
      .each(function(d) {
        const self = d3.select(this);
        const date = new Date(d);
        self.text(''); // Clear the current text
        self.append('tspan')
          .attr('x', 0)
          .attr('dy', '0em') // First line (month/day)
          .text(formatMonthDay(date));
        self.append('tspan')
          .attr('x', 0)
          .attr('dy', '1em') // Second line (year)
          .text(formatYear(date));
      });

    const yAxis = d3.axisLeft(y);
    g.append('g')
      .call(yAxis)
      .selectAll('text')
      .style('fill', 'black');

    // Clean up the tooltip div when the component unmounts
    return () => {
      tooltip.remove();
    };
  }, [data]);


  return (
    
    <div className="flex flex-col grow p-4 rounded-xl border border-gray-300 border-solid max-md:px-3 max-md:mt-4 max-md:max-w-full">
      <div className="text-base font-medium leading-6 text-neutral-900 mb-2">
        Time Series Graph
      </div>
      <div className="relative" style={{ paddingBottom: '50%', height: 0, overflow: 'hidden' }}>
        <svg ref={svgRef} className="absolute top-0 left-0 w-full h-full"></svg>
      </div>
    </div>
  );
};

export default TimeSeriesGraph;
