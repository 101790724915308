import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Air from './Components/Air';
import Forest from './Components/Forest';
import Header from './Components/Header';
import Weather from './Components/Weather';
import Water from './Components/Water';
import Species from './Components/Species'
import DetailSpeciesView from './Components/DetailSpeciesView';
import DetailAreaView from './Components/DetailAreaView';
import Observations from './Components/Observations';
import './App.css';

function App() {

  return (
    <div className="app-container bg-white w-full">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Weather />} />
          <Route path="/air" element={<Air />} />
          <Route path="/water" element={<Water />} />
          <Route path="/forest" element={<Forest />} />
          <Route path="/species" element={<Species />} />
          <Route path="/detailspeciesview" element={<DetailSpeciesView />} />
          <Route path="/detailareaview" element={<DetailAreaView />} />
          <Route path="/observations" element={<Observations />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
