import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

function BarChart({ data, xLabel, yLabel, xDataKey, yDataKey, barColor }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const margin = { top: 20, right: 30, bottom: 50, left: 70 };
    const containerWidth = chartRef.current.parentElement.clientWidth;
    const width = containerWidth - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    d3.select(chartRef.current).selectAll('*').remove();

    const svg = d3.select(chartRef.current)
      .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // X-scale now using a linear scale for percentage ranges
    const x = d3.scaleLinear()
      .domain([0, 100])
      .range([0, width]);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d[yDataKey])])
      .nice()
      .range([height, 0]);

    // Adjust tooltip creation to only display land area (hectares)
    const tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'white')
      .style('border', '1px solid #ccc')
      .style('padding', '8px')
      .style('border-radius', '4px')
      .style('box-shadow', '0px 0px 6px rgba(0, 0, 0, 0.1)');

    svg.selectAll('.bar')
      .data(data)
      .enter().append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d[xDataKey]) + 5)  // Adding a small gap (5px) between bars
      .attr('y', d => y(d[yDataKey]))
      .attr('width', d => x(10) - x(0) - 10)  // Adjust width to include spacing (10px total gap)
      .attr('height', d => height - y(d[yDataKey]))
      .attr('fill', barColor)
      .style('cursor', 'pointer')
      .on('mouseover', function (event, d) {
        d3.select(this).attr('fill', 'orange');
        tooltip.html(`${yLabel}: ${d[yDataKey].toFixed(2)}`)
          .style('visibility', 'visible');
      })
      .on('mousemove', function (event) {
        tooltip.style('top', `${event.pageY - 20}px`)
          .style('left', `${event.pageX + 10}px`);
      })
      .on('mouseout', function () {
        d3.select(this).attr('fill', barColor);
        tooltip.style('visibility', 'hidden');
      });

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).tickFormat(d => `${d}%`).ticks(10));

    svg.append('g')
      .call(d3.axisLeft(y).tickFormat(d => d3.format(".2s")(d)));

    svg.append('text')
      .attr('transform', `translate(${width / 2},${height + margin.bottom - 10})`)
      .style('text-anchor', 'middle')
      .text(xLabel);

    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 2)
      .attr('y', -margin.left + 20)
      .style('text-anchor', 'middle')
      .text(yLabel);

  }, [data, xLabel, yLabel, xDataKey, yDataKey, barColor]);

  return (
    <div className="w-full max-w-4xl mx-auto p-4 bg-white border border-gray-300 rounded-lg shadow-md">
      <div className="h-96">
        <svg ref={chartRef} className="w-full h-full"></svg>
      </div>
    </div>
  );
}

export default BarChart;
