import React, { useEffect, useRef } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import OSM from 'ol/source/OSM';
import WMSSource from 'ol/source/TileWMS'; // Import WMSSource
import { GeoJSON } from 'ol/format'; // Import GeoJSON format

function ForestMap({ areas, zoomToArea }) {
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const vectorSourceRef = useRef(new VectorSource());
  
  useEffect(() => {
    if (mapRef.current) return; // Prevent multiple map instances

    // Calculate date range
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD

    const tenDaysAgo = new Date();
    tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
    const tenDaysAgoString = tenDaysAgo.toISOString().split('T')[0]; // Format: YYYY-MM-DD

    // Set up WMS source with dynamic TIME parameter
    const wmsSource = new WMSSource({
      url: 'https://services.sentinel-hub.com/ogc/wms/955ec531-78b1-41aa-a743-00f270dd29389',
      params: {
        'LAYERS': 'NDVI',
        'FORMAT': 'image/png',
        'TRANSPARENT': true,
        'SHOWLOGO': false,
        'TIME': `${tenDaysAgoString}/${currentDateString}`,
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous',
    });

    const wmsLayer = new TileLayer({
      source: wmsSource,
    });

    const satelliteLayer = new TileLayer({
      source: new XYZ({
        url: 'http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      }),
    });

    const osmLayer = new TileLayer({
      source: new OSM(),
    });

    const vectorLayer = new VectorLayer({
      source: vectorSourceRef.current,
    });

    mapRef.current = new Map({
      target: mapContainerRef.current,
      layers: [satelliteLayer, vectorLayer, wmsLayer], // Add WMS layer here
      view: new View({
        center: fromLonLat([-90.4996, 15.6093]),
        zoom: 7,
      }),
    });

    // Load GeoJSON data into the vector source
    const format = new GeoJSON();
    areas.forEach(area => {
      const geojsonData = area.geojson;
      const features = format.readFeatures(geojsonData, {
        featureProjection: 'EPSG:3857', // Web Mercator projection
      });
      vectorSourceRef.current.addFeatures(features);
    });

    // Layer switcher logic
    document.getElementById('layerSwitcher').addEventListener('change', (event) => {
      if (event.target.value === 'satellite') {
        mapRef.current.getLayers().setAt(0, satelliteLayer);
      } else if (event.target.value === 'osm') {
        mapRef.current.getLayers().setAt(0, osmLayer);
      } else if (event.target.value === 'wms') {
        mapRef.current.getLayers().setAt(0, wmsLayer);
      }
    });
  }, [areas]);

  useEffect(() => {
    if (zoomToArea && mapRef.current) {
      // Clear previous features
      vectorSourceRef.current.clear();
      // Re-add selected area
      const format = new GeoJSON();
      const geojsonData = zoomToArea;
      const features = format.readFeatures(geojsonData, {
        featureProjection: 'EPSG:3857', // Web Mercator projection
      });
      vectorSourceRef.current.addFeatures(features);

      // Zoom to the selected area with animation
      features.forEach(feature => {
        const polygon = feature.getGeometry();
        const extent = polygon.getExtent();
        const view = mapRef.current.getView();
        const resolution = view.getResolutionForExtent(extent, mapRef.current.getSize());
        const zoom = view.getZoomForResolution(resolution);
        const center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];

        view.animate({
          center: center,
          zoom: Math.min(zoom, 19), // Adjust max zoom level as needed
          duration: 2000 // Duration in milliseconds
        });
      });
    }
  }, [zoomToArea]);

  return (
    <div className="w-full h-full relative">
      <select id="layerSwitcher" className="absolute top-2 right-2 z-10 bg-white p-1">
        <option value="satellite">Satellite</option>
        <option value="osm">OSM</option>
        <option value="wms">WMS</option> {/* Option for WMS layer */}
      </select>
      <div ref={mapContainerRef} className="w-full h-full" />
    </div>
  );
}

export default ForestMap;
