import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaWind } from "react-icons/fa";
import { WiHumidity, WiBarometer, WiRain } from "react-icons/wi";
import { CiTempHigh } from "react-icons/ci";
import { ThreeDots } from "react-loader-spinner";
import Map from "./Map";
import Card from "./Card";
import GCard from "./GCard";

function Weather() {
  const [lat, setLat] = useState(14.628434);
  const [lng, setLng] = useState(-90.522713);
  const [locationName, setLocationName] = useState("Guatemala City, Guatemala");

  const [weatherData, setWeatherData] = useState({
    temperature: null,
    humidity: null,
    windSpeed: null,
    pressure: null,
    rain: null,
    rainPeriod: null,
  });
  const [forecastData, setForecastData] = useState([]);
  const [geojsonFeatures, setGeojsonFeatures] = useState([]);
  const [stationsData, setStationsData] = useState([]);

  const handlePointClick = (pointInfo) => {
    setLat(pointInfo.lat);
    setLng(pointInfo.lng);
    setLocationName(`${pointInfo.ADM1_ES || "Unknown"}, Guatemala`);
  };

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=a4727eca53146e091e3d4b5789e6398c`
        );
        const data = response.data;
        const rain = data.rain ? data.rain["1h"] || data.rain["3h"] : "No rain";
        const rainPeriod = data.rain
          ? data.rain["1h"]
            ? "last hour"
            : "last 3 hours"
          : "";
        setWeatherData({
          temperature: (data.main.temp - 273.15).toFixed(2),
          humidity: data.main.humidity,
          windSpeed: data.wind.speed,
          pressure: data.main.pressure,
          rain: rain,
          rainPeriod: rainPeriod,
        });
      } catch (error) {
        console.error("Error fetching weather data", error);
      }
    };

    const fetchForecastData = async () => {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=a4727eca53146e091e3d4b5789e6398c`
        );
        const data = response.data.list;
        const selectedData = [data[0], data[8], data[16], data[24], data[32]];
        setForecastData(selectedData);
      } catch (error) {
        console.error("Error fetching forecast data", error);
      }
    };

    fetchWeatherData();
    fetchForecastData();
  }, [lat, lng]);

  useEffect(() => {
    const fetchGeoJSON = async () => {
      try {
        const response = await fetch("/stations.geojson");
        const geojson = await response.json();
        setGeojsonFeatures(geojson.features);
      } catch (error) {
        console.error("Error fetching GeoJSON:", error);
      }
    };

    fetchGeoJSON();
  }, []);

  useEffect(() => {
    const fetchStationsData = async () => {
      const fetchedData = await Promise.all(
        geojsonFeatures.map(async (feature) => {
          const { coordinates } = feature.geometry;
          const { ADM1_ES } = feature.properties;
          const [lng, lat] = coordinates;
          try {
            const response = await axios.get(
              `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=a4727eca53146e091e3d4b5789e6398c`
            );
            const data = response.data;
        
       
            return {
              name: ADM1_ES,
              location: `Lat: ${lat}, Lng: ${lng}`,
              temperature: data.main.temp ,
              humidity: data.main.humidity,
              windSpeed: data.wind.speed,
              pressure: data.main.pressure
            
            };
          } catch (error) {
            console.error("Error fetching station data", error);
            return null;
          }
        })
      );
      setStationsData(fetchedData.filter((data) => data !== null));
    };

    if (geojsonFeatures.length > 0) {
      fetchStationsData();
    }
  }, [geojsonFeatures]);

  const graphData = forecastData.map((entry) => {
    const date = new Date(entry.dt * 1000);
    return {
      date: date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      }),
      temperature: (entry.main.temp - 273.15).toFixed(2),
      pressure: entry.main.pressure,
      windSpeed: entry.wind.speed,
      humidity: entry.main.humidity,
    };
  });

  const getTemperatureStatus = (temp) => {
    if (temp < 0) return "Freezing";
    if (temp <= 15) return "Cold";
    if (temp <= 25) return "Mild";
    return "Hot";
  };

  const getPressureStatus = (pressure) => {
    if (pressure < 1010) return "Low";
    if (pressure <= 1020) return "Normal";
    return "High";
  };

  const getWindSpeedStatus = (speed) => {
    if (speed < 1) return "Calm";
    if (speed <= 5) return "Light";
    if (speed <= 10) return "Moderate";
    return "Strong";
  };

  const getHumidityStatus = (humidity) => {
    if (humidity < 30) return "Low";
    if (humidity <= 60) return "Normal";
    return "High";
  };

  return (
    <div className="flex flex-col justify-center bg-white w-full">
      <div className="flex flex-col items-center pb-5 w-full bg-slate-50 max-md:max-w-full">
      
        <div className="flex flex-col px-4 mt-9 w-full max-w-[960px] max-md:max-w-full">
          <div className="self-start text-3xl font-bold text-neutral-900">
            Weather Dashboard
          </div>
          <div className="self-start mt-3 text-sm leading-5 text-slate-500">
            {locationName}
          </div>
          <div className="flex gap-3 mt-8 max-md:flex-wrap">
            <Card
              icon={<CiTempHigh className="text-lg h-8 w-8 text-[#F44336]" />}
              value={
                weatherData.temperature !== null ? (
                  `${weatherData.temperature} °C`
                ) : (
                  <ThreeDots color="#00BFFF" height={20} width={20} />
                )
              }
              unit=""
              label="Temperature"
              status={weatherData.temperature !== null ? getTemperatureStatus(weatherData.temperature) : "N/A"}
            />
            <Card
              icon={<WiBarometer className="text-lg h-8 w-8 text-[#F44336]" />}
              value={
                weatherData.pressure !== null ? (
                  `${weatherData.pressure} hPa`
                ) : (
                  <ThreeDots color="#00BFFF" height={20} width={20} />
                )
              }
              unit=""
              label="Pressure"
              status={weatherData.pressure !== null ? getPressureStatus(weatherData.pressure) : "N/A"}
            />
            <Card
              icon={<FaWind className="text-lg h-8 w-8 text-[#03A9F4]" />}
              value={
                weatherData.windSpeed !== null ? (
                  `${weatherData.windSpeed} m/s`
                ) : (
                  <ThreeDots color="#00BFFF" height={20} width={20} />
                )
              }
              unit=""
              label="Wind Speed"
              status={weatherData.windSpeed !== null ? getWindSpeedStatus(weatherData.windSpeed) : "N/A"}
            />
            <Card
              icon={<WiHumidity className="text-lg h-8 w-8 text-[#2196F3]" />}
              value={
                weatherData.humidity !== null ? (
                  `${weatherData.humidity}%`
                ) : (
                  <ThreeDots color="#00BFFF" height={20} width={20} />
                )
              }
              unit=""
              label="Humidity"
              status={weatherData.humidity !== null ? getHumidityStatus(weatherData.humidity) : "N/A"}
            />
            <Card
              icon={<WiRain className="text-lg h-8 w-8 text-[#2196F3]" />}
              value={
                weatherData.rain !== "No rain" ? (
                  <>
                    {`${weatherData.rain} mm`}
                    <span className="text-xs text-gray-500"> ({weatherData.rainPeriod})</span>
                  </>
                ) : (
                  "No rain"
                )
              }
              unit=""
              label="Rain"
            
            />
          </div>
          <div className="flex flex-col mt-5">
            <Map onPointClick={handlePointClick} showLayers={true} stationsData={stationsData}/>
          </div>
          <div className="mt-8 text-2xl font-bold leading-7 text-neutral-900 max-md:max-w-full">
            5 days forecast 
          </div>
          <div className="grid grid-cols-2 gap-4 mt-5 max-md:grid-cols-1">
            <GCard title="Temperature(°C)" graphData={graphData.map(data => ({ date: data.date, value: data.temperature }))} />
            <GCard title="Pressure(hPa)" graphData={graphData.map(data => ({ date: data.date, value: data.pressure }))} />
            <GCard title="Wind Speed(m/s)" graphData={graphData.map(data => ({ date: data.date, value: data.windSpeed }))} />
            <GCard title="Humidity(%)" graphData={graphData.map(data => ({ date: data.date, value: data.humidity }))} />
          </div>
          <div className="mt-4 text-lg font-bold leading-6 text-neutral-900 max-md:max-w-full">
            Weather Monitoring Stations Overview
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-slate-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Station Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Latest Readings
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {stationsData.map((station, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {station.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {station.location}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      Temperature: {station.temperature} °C, 
                      Rain: {station.rain} {station.rainPeriod && <span className="text-xs text-gray-500">({station.rainPeriod})</span>},
                      Humidity: {station.humidity}%, 
                      Wind Speed: {station.windSpeed} m/s, 
                      Pressure: {station.pressure} hPa
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weather;
