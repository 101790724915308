import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { NavigationControl, FullscreenControl } from 'maplibre-gl';

const layers = [
  { id: 'clouds', name: 'Clouds', layer: 'clouds_new' },
  { id: 'precipitation', name: 'Precipitation', layer: 'precipitation_new' },
  { id: 'pressure', name: 'Pressure', layer: 'pressure_new' },
  { id: 'wind', name: 'Wind', layer: 'wind_new' },
  { id: 'temperature', name: 'Temperature', layer: 'temp_new' }
];

const Map = ({ onPointClick, selectedPoint, showLayers, stationsData }) => {
  const mapPanelRef = useRef(null);
  const mapRef = useRef(null);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [animatedPoint, setAnimatedPoint] = useState(null);
  const [activeLayer, setActiveLayer] = useState(layers[0].id);
  const [selectedADM1, setSelectedADM1] = useState('');
  const [heatmapOptions, setHeatmapOptions] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState('');
 

    
  const parameterRanges = {
    temperature: { min: 270, max: 310, range: [0, 1] },
    pressure: { min: 1000, max: 1020, range: [0, 1] },
    windSpeed: { min: 1, max: 4, range: [0, 1] },
    humidity: { min: 50, max: 100, range: [0, 1] },
    aqi: { min: 0, max: 5, range: [0, 1] },
    co: { min: 0, max: 1100, range: [0, 1] },
    no: { min: 0, max: 2.5, range: [0, 1] },
    no2: { min: 0, max: 10, range: [0, 1] },
    o3: { min: 1, max: 30, range: [0, 1] },
    so2: { min: 0, max: 100, range: [0, 1] },
    pm2_5: { min: 5, max: 80, range: [0, 1] },
    pm10: { min: 5, max: 110, range: [0, 1] },
    nh3: { min: 0, max: 60, range: [0, 1] }
  };
 // Extract unique parameters from stationsData
 useEffect(() => {
  if(stationsData){
  const uniqueParams = new Set();
  stationsData.forEach(station => {
    Object.keys(station).forEach(param => {
      if (param !== 'name' && param !== 'location') {
        uniqueParams.add(param);
      }
    });
  });
  
 setHeatmapOptions([...uniqueParams]);
}
}, [stationsData]);

  // Generate GeoJSON from stationsData for heatmap
  const generateGeoJSON = (data, parameter) => {
    return {
      type: 'FeatureCollection',
      features: data.map(station => {
        const [lat, lng] = station.location.replace('Lat: ', '').replace('Lng: ', '').split(', ').map(Number);
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
          properties: {
            value: station[parameter],
          },
        };
      }),
    };
  };


  const handleLayerChange = (layerId) => {
    setActiveLayer(layerId);

    const selectedLayer = layers.find(layer => layer.id === layerId);

    mapRef.current.getSource('weather-layer').tiles = [
      `https://tile.openweathermap.org/map/${selectedLayer.layer}/{z}/{x}/{y}.png?appid=a4727eca53146e091e3d4b5789e6398c`
    ];
    mapRef.current.style.sourceCaches['weather-layer'].clearTiles();
    mapRef.current.style.sourceCaches['weather-layer'].update(mapRef.current.transform);
  };

 // Update heatmap layer based on selected parameter
   // Update heatmap layer based on selected parameter
   useEffect(() => {
    if (mapRef.current && selectedParameter && stationsData) {
      const geojsonData = generateGeoJSON(stationsData, selectedParameter);
      const range = parameterRanges[selectedParameter] || { min: 0, max: 1, range: [0, 1] };
      console.log(range);

      // Remove previous heatmap layer and source if they exist
      if (mapRef.current.getLayer('heatmap-layer')) {
        mapRef.current.removeLayer('heatmap-layer');
      }
      if (mapRef.current.getSource('heatmap')) {
        mapRef.current.removeSource('heatmap');
      }

      // Add new source and layer
      mapRef.current.addSource('heatmap', {
        type: 'geojson',
        data: geojsonData,
      });

      mapRef.current.addLayer({
        id: 'heatmap-layer',
        type: 'heatmap',
        source: 'heatmap',
        maxzoom: 9,
        paint: {
          'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'value'],
            range.min,
            0,
            range.max,
            1
          ],
          'heatmap-intensity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            1,
            9,
            3,
          ],
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0)',
            0.1,
            'rgb(103,169,207)',
            0.3,
            'rgb(209,229,240)',
            0.5,
            'rgb(253,219,199)',
            0.7,
            'rgb(239,138,98)',
            0.9,
            'rgb(178,24,43)',
          ],
          'heatmap-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            15,
            9,
            40,
          ],
          'heatmap-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7,
            1,
            9,
            0,
          ],
        },
      });
    }
  }, [selectedParameter, stationsData]);
  

  useEffect(() => {
    const fetchGeoJSON = async () => {
      try {
        const response = await fetch('/stations.geojson');
        const geojson = await response.json();
        setFilteredFeatures(geojson.features);
      } catch (error) {
        console.error('Error fetching GeoJSON:', error);
      }
    };

    fetchGeoJSON();
  }, []);

  useEffect(() => {
    if (mapPanelRef.current && filteredFeatures.length > 0 && !mapRef.current) {
      mapRef.current = new maplibregl.Map({
        container: mapPanelRef.current,
        style: 'https://api.maptiler.com/maps/streets-v2/style.json?key=Otbh9YhFMbwux7HyoffB',
        center: [-90.2308, 15.7835],
        zoom: 6,
        pitch: 20,
      });

      mapRef.current.addControl(new NavigationControl(), 'top-right');
      mapRef.current.addControl(new FullscreenControl(), 'bottom-right');

      mapRef.current.on('load', () => {
        mapRef.current.addSource('gtm', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: filteredFeatures,
          },
        });

        mapRef.current.addLayer({
          id: 'gtm-point-layer',
          type: 'circle',
          source: 'gtm',
          paint: {
            'circle-radius': 6,
            'circle-color': ['case', ['==', ['get', 'ADM1_ES'], selectedPoint ? selectedPoint.ADM1_ES : ''], '#00FF00', '#FF0000'],
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff',
          },
        });

        mapRef.current.on('click', 'gtm-point-layer', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const adm1_es = e.features[0].properties.ADM1_ES;

          if (onPointClick) {
            onPointClick({ lat: coordinates[1], lng: coordinates[0], ADM1_ES: adm1_es });
          }

          setAnimatedPoint(e.features[0]);
        });

        if (showLayers) {
          mapRef.current.addSource('weather-layer', {
            type: 'raster',
            tiles: [
              `https://tile.openweathermap.org/map/${layers[0].layer}/{z}/{x}/{y}.png?appid=a4727eca53146e091e3d4b5789e6398c`
            ],
            tileSize: 256,
          });

          mapRef.current.addLayer({
            id: 'weather-layer',
            type: 'raster',
            source: 'weather-layer',
            paint: {
              'raster-opacity': 1,
            },
          });
        }
      });
    }
  }, [filteredFeatures, onPointClick, selectedPoint, showLayers]);

  useEffect(() => {
    if (mapRef.current && selectedPoint) {
      const updatedFeatures = filteredFeatures.map((feature) => {
        if (feature.properties.ADM1_ES === selectedPoint.ADM1_ES) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              selected: true,
            },
          };
        } else {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              selected: false,
            },
          };
        }
      });

      mapRef.current.getSource('gtm').setData({
        type: 'FeatureCollection',
        features: updatedFeatures,
      });

      setAnimatedPoint(updatedFeatures.find(feature => feature.properties.ADM1_ES === selectedPoint.ADM1_ES));
    }
  }, [selectedPoint, filteredFeatures]);

  useEffect(() => {
    if (mapRef.current && animatedPoint) {
      let radius = 6;
      const animateCircleRadius = () => {
        radius = radius === 6 ? 12 : 6;
        mapRef.current.setPaintProperty('gtm-point-layer', 'circle-radius', [
          'case',
          ['==', ['get', 'ADM1_ES'], animatedPoint.properties.ADM1_ES],
          radius,
          6,
        ]);
        requestAnimationFrame(animateCircleRadius);
      };
      animateCircleRadius();
    }
  }, [animatedPoint]);

  const handleADM1Change = (event) => {
    const selectedADM1 = event.target.value;
    setSelectedADM1(selectedADM1);
    
    const feature = filteredFeatures.find(f => f.properties.ADM1_ES === selectedADM1);
    if (feature) {
      const coordinates = feature.geometry.coordinates;
      if (onPointClick) {
        onPointClick({ lat: coordinates[1], lng: coordinates[0], ADM1_ES: selectedADM1 });
      }
      setAnimatedPoint(feature);
    }
  };

  return (
    <div className="map-container relative">
 

  <div className="mb-4  dropdown-container">
    {/* <label htmlFor="adm1-select" className="block text-gray-700 font-medium mb-1">Region:</label> */}
    <select
      id="adm1-select"
      value={selectedADM1}
      onChange={handleADM1Change}
      className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <option value="">Select a station</option>
      {filteredFeatures.map((feature) => (
        <option key={feature.properties.ADM1_ES} value={feature.properties.ADM1_ES}>
          {feature.properties.ADM1_ES}
        </option>
      ))}
    </select>
  </div>
<div className=" mb-4 flex-nowrap">
    {showLayers && layers.map(layer => (
      <button
        key={layer.id}
        onClick={() => handleLayerChange(layer.id)}
        className={`px-4 py-2 rounded-lg text-white mb-8  font-semibold transition-colors duration-300 ${
          layer.id === activeLayer ? 'bg-green-500' : 'bg-gray-200 hover:bg-gray-300'
        }`}
      >
        {layer.name}
      </button>
    ))}
  </div>
  
  <div className="heatmap-controls">
   
    <select
      id="parameter-select"
      value={selectedParameter}
      onChange={(e) => {
        const parameter = e.target.value;
        setSelectedParameter(parameter);
       
      }}
      className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <option value="">Select Heat Map Parameter</option>
      {heatmapOptions.map(param => (
        <option key={param} value={param}>
          {param}
        </option>
      ))}
    </select>
  </div>
  <div ref={mapPanelRef} className="map mt-5 h-80 w-full mb-4" />

</div>

  );
};

export default Map;
