import React, { useEffect, useState } from "react";
import { AiOutlineWarning, AiOutlineCheckCircle } from "react-icons/ai";
import Map from "./Map_Water";
import Card from "./Card";
import GCard from "./GCard";

function Water() {
  const [waterData, setWaterData] = useState({
    pH: null,
    dissolvedOxygen: null,
    turbidity: null,
    nitrates: null,
    temperature: null,
  });

  const graphData = [
    { date: "Jan 1", value: 5 },
    { date: "Jan 2", value: 6 },
    { date: "Jan 3", value: 4 },
    { date: "Jan 4", value: 7 },
    { date: "Jan 5", value: 6 },
    { date: "Jan 6", value: 5 },
    { date: "Jan 7", value: 6 },
  ]; // Example data for the last 7 days

  const getStatus = (value, type) => {
    const thresholds = {
      pH: {
        good: [6.5, 8.5],
        moderate: [5, 6.5],
        unhealthy: [4, 5],
        veryUnhealthy: [3, 4],
        hazardous: [0, 3],
      },
      dissolvedOxygen: {
        good: 8,
        moderate: 6,
        unhealthy: 4,
        veryUnhealthy: 2,
        hazardous: 0,
      },
      turbidity: {
        good: 1,
        moderate: 5,
        unhealthy: 10,
        veryUnhealthy: 15,
        hazardous: 20,
      },
      nitrates: {
        good: 1,
        moderate: 3,
        unhealthy: 5,
        veryUnhealthy: 10,
        hazardous: 20,
      },
      temperature: {
        good: 20,
        moderate: 25,
        unhealthy: 30,
        veryUnhealthy: 35,
        hazardous: 40,
      },
    };

    const threshold = thresholds[type];

    if (type === "pH") {
      if (value >= threshold.good[0] && value <= threshold.good[1])
        return "Good";
      if (value >= threshold.moderate[0] && value <= threshold.moderate[1])
        return "Moderate";
      if (value >= threshold.unhealthy[0] && value <= threshold.unhealthy[1])
        return "Unhealthy";
      if (
        value >= threshold.veryUnhealthy[0] &&
        value <= threshold.veryUnhealthy[1]
      )
        return "Very Unhealthy";
      return "Hazardous";
    } else {
      if (value <= threshold.good) return "Good";
      if (value <= threshold.moderate) return "Moderate";
      if (value <= threshold.unhealthy) return "Unhealthy";
      if (value <= threshold.veryUnhealthy) return "Very Unhealthy";
      return "Hazardous";
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case "Good":
        return <AiOutlineCheckCircle className="text-green-500" />;
      case "Moderate":
        return <AiOutlineCheckCircle className="text-yellow-500" />;
      case "Unhealthy":
        return <AiOutlineWarning className="text-orange-500" />;
      case "Very Unhealthy":
        return <AiOutlineWarning className="text-red-500" />;
      case "Hazardous":
        return <AiOutlineWarning className="text-purple-500" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    // Replace with actual API call if available
    setWaterData({
      pH: 7.2,
      dissolvedOxygen: 8.5,
      turbidity: 2.5,
      nitrates: 1.2,
      temperature: 18.3,
    });
  }, []);

  return (
    <div className="flex flex-col justify-center bg-white w-full">
      <div className="flex flex-col items-center pb-5 w-full bg-slate-50 max-md:max-w-full">
    
        <div className="flex flex-col px-4 mt-9 w-full max-w-[960px] max-md:max-w-full">
          <div className="self-start text-3xl font-bold text-neutral-900">
            Water Quality Dashboard
          </div>
          <div className="self-start mt-3 text-sm leading-5 text-slate-500">
          Guatemala
          </div>
          <div className="flex gap-3 mt-8 max-md:flex-wrap">
            <Card
              icon={getIcon(getStatus(waterData.pH, "pH"))}
              value={waterData.pH}
              unit=""
              label="pH"
              status={getStatus(waterData.pH, "pH")}
            />
            <Card
              icon={getIcon(
                getStatus(waterData.dissolvedOxygen, "dissolvedOxygen")
              )}
              value={waterData.dissolvedOxygen}
              unit="mg/L"
              label="Dissolved Oxygen"
              status={getStatus(waterData.dissolvedOxygen, "dissolvedOxygen")}
            />
            <Card
              icon={getIcon(getStatus(waterData.turbidity, "turbidity"))}
              value={waterData.turbidity}
              unit="NTU"
              label="Turbidity"
              status={getStatus(waterData.turbidity, "turbidity")}
            />
            <Card
              icon={getIcon(getStatus(waterData.nitrates, "nitrates"))}
              value={waterData.nitrates}
              unit="mg/L"
              label="Nitrates"
              status={getStatus(waterData.nitrates, "nitrates")}
            />
            <Card
              icon={getIcon(getStatus(waterData.temperature, "temperature"))}
              value={waterData.temperature}
              unit="°C"
              label="Temperature"
              status={getStatus(waterData.temperature, "temperature")}
            />
          </div>
          <div className="flex flex-col mt-5">
            <Map  />
          </div>
          <div className="mt-8 text-2xl font-bold leading-7 text-neutral-900 max-md:max-w-full">
            Historical Water Quality
          </div>
          <div className="grid grid-cols-2 gap-4 mt-5 max-md:grid-cols-1">
            <GCard title="pH" graphData={graphData} />
            <GCard title="Dissolved Oxygen" graphData={graphData} />
            <GCard title="Turbidity" graphData={graphData} />
            <GCard title="Nitrates" graphData={graphData} />
          </div>
          <div className="mt-4 text-lg font-bold leading-6 text-neutral-900 max-md:max-w-full">
        Water Quality Monitoring Stations Overview
      </div>
      <div className="overflow-x-auto">
  <table className="min-w-full divide-y divide-gray-200">
    <thead className="bg-slate-50">
      <tr>
        <th
          scope="col"
          className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Station Name
        </th>
        <th
          scope="col"
          className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Location
        </th>
        <th
          scope="col"
          className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Latest Readings
        </th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          Station A
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          Lake Atitlán
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          pH: 7.5, Dissolved Oxygen: 8.2 mg/L, Turbidity: 3.1 NTU, Nitrate: 2.5 mg/L, Phosphate: 0.1 mg/L
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          Station B
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          Rio Dulce
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          pH: 7.8, Dissolved Oxygen: 7.5 mg/L, Turbidity: 4.2 NTU, Nitrate: 1.8 mg/L, Phosphate: 0.05 mg/L
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          Station C
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          Lake Izabal
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          pH: 7.2, Dissolved Oxygen: 8.0 mg/L, Turbidity: 2.9 NTU, Nitrate: 2.1 mg/L, Phosphate: 0.08 mg/L
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          Station D
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          Rio Motagua
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          pH: 7.6, Dissolved Oxygen: 7.8 mg/L, Turbidity: 3.5 NTU, Nitrate: 1.9 mg/L, Phosphate: 0.07 mg/L
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          Station E
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          Lake Petén Itzá
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          pH: 7.4, Dissolved Oxygen: 8.5 mg/L, Turbidity: 2.7 NTU, Nitrate: 2.3 mg/L, Phosphate: 0.09 mg/L
        </td>
      </tr>
    </tbody>
  </table>
</div>



        </div>
      </div>
    </div>
  );
}

export default Water;
