import React from 'react';

function Card({ icon, value, unit, label, status }) {
  return (
    <div className="flex flex-col items-center p-4 w-full max-w-xs md:max-w-sm lg:max-w-md bg-white rounded-lg shadow-md m-2">
      <div className="text-3xl mb-2">{icon}</div>
      <div className="text-sm text-gray-500">{label}</div>
      <div className="text-xl font-semibold text-black">{value} {unit}</div>
      <div className="text-sm text-gray-500">{status}</div>
    </div>
  );
}

export default Card;
