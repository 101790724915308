import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SpeciesMap from './SpeciesMap';
import GCard from './GCard';
import { HiChevronLeft } from 'react-icons/hi';
import axios from 'axios';

function DetailSpeciesView() {
  const [details, setDetails] = useState(null); // Changed to null for the loader
  const [forecastData, setForecastData] = useState([]);
  const [loadingMap, setLoadingMap] = useState(true); // State for map loading
  const location = useLocation();
  const navigate = useNavigate();
  const speciesId = location.state?.id;

  useEffect(() => {
    if (speciesId) {
      fetch(`https://api.terra-metrics.com/api/species/${speciesId}/`)
        .then(response => response.json())
        .then(data => {
          setDetails(data);
          setLoadingMap(true); // Set loadingMap to true when fetching details
        })
        .catch(error => console.error('Error fetching species details:', error));
    }
  }, [speciesId]);

  useEffect(() => {
    if (details?.latitude && details?.longitude) {
      const fetchForecastData = async () => {
        try {
          const response = await axios.get(
            `https://api.openweathermap.org/data/2.5/forecast?lat=${details.latitude}&lon=${details.longitude}&appid=a4727eca53146e091e3d4b5789e6398c`
          );
          const data = response.data.list;
          const selectedData = [data[0], data[8], data[16], data[24], data[32]];
          setForecastData(selectedData);
        } catch (error) {
          console.error('Error fetching forecast data', error);
        }
      };

      fetchForecastData();
    }
  }, [details]);

  useEffect(() => {
    if (!loadingMap && details?.latitude && details?.longitude) {
      setLoadingMap(false); // Set loadingMap to false when map data is loaded
    }
  }, [loadingMap, details]);

  const graphData = forecastData.map(entry => {
    const date = new Date(entry.dt * 1000);
    return {
      date: date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      temperature: (entry.main.temp - 273.15).toFixed(2),
      pressure: entry.main.pressure,
      windSpeed: entry.wind.speed,
      humidity: entry.main.humidity,
    };
  });

  const coordinates = [details?.longitude || 15.783, details?.latitude || -89.583];

  return (
    <div className="flex flex-col justify-center bg-white w-full">
      <div className="flex flex-col items-center pb-5 w-full bg-slate-50 max-md:max-w-full">
        <div className="flex flex-col px-4 mt-9 w-full max-w-[960px] max-md:max-w-full">
          <div className="self-start text-3xl font-bold text-neutral-900">
            Endangered Species Dashboard
          </div>
          <div className="self-start mt-3 text-sm leading-5 text-slate-500">
            Guatemala
          </div>
        </div>
        <div className="flex justify-center w-full px-10 py-3 border-b border-gray-200">
          <button
            onClick={() => navigate('/species')}
            className="flex items-center text-blue-500 hover:text-blue-700"
          >
            <HiChevronLeft className="h-6 w-6 mr-2" />
            Back
          </button>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center bg-white w-full max-w-[960px] mx-auto p-6 gap-6">
        <div className="flex flex-col w-full lg:w-1/2 p-4">
          {details ? (
            <>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">{details.name}</h2>
              <img src={details.image} alt="Species" className="w-full h-auto mb-4 object-cover rounded-lg shadow-md" />
              <h3 className="text-2xl font-bold mb-2 text-gray-800">Description</h3>
              <p className="text-gray-700 mb-4">{details.description}</p>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Taxonomy</h3>
              <ul className="mb-4">
                <li><strong>Kingdom:</strong> {details.kingdom}</li>
                <li><strong>Phylum:</strong> {details.phylum}</li>
                <li><strong>Class:</strong> {details.class_name}</li>
                <li><strong>Order:</strong> {details.order}</li>
                <li><strong>Family:</strong> {details.family}</li>
                <li><strong>Genus:</strong> {details.genus}</li>
                <li><strong>Feed Type:</strong> {details.feed_type}</li>
              </ul>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Range & Habitat</h3>
              <ul className="mb-4">
                <li><strong>Native/Extant:</strong> {details.native_extant}</li>
                <li><strong>Range Description:</strong> {details.range_description}</li>
                <li><strong>Upper Elevation Limit:</strong> {details.upper_elevation_limit} m</li>
                <li><strong>Lower Elevation Limit:</strong> {details.lower_elevation_limit} m</li>
                <li><strong>Habitat Type:</strong> {details.habitat_type}</li>
                <li><strong>Habitat Description:</strong> {details.habitat_description}</li>
              </ul>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Population & Threats</h3>
              <ul className="mb-4">
                <li><strong>Current Population Trend:</strong> {details.current_population_trend}</li>
                <li><strong>Number of Mature Individuals:</strong> {details.number_of_mature_individuals}</li>
                <li><strong>Population Description:</strong> {details.population_description}</li>
                <li><strong>Threats:</strong> {details.threats_bullets}</li>
                <li><strong>Threats Description:</strong> {details.threats_description}</li>
              </ul>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Sources and Credits</h3>
              <p className="text-gray-700 mb-4">{details.source}</p>
              <p className="text-gray-700 mb-4">{details.credit}</p>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">More Info</h3>
              <a href={details.more_info_url} className="text-blue-600" target="_blank" rel="noopener noreferrer">
                {details.more_info_url}
              </a>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Project</h3>
              <a href={details.project_url} className="text-blue-600" target="_blank" rel="noopener noreferrer">
                {details.project_url}
              </a>
            </>
          ) : (
            <div className="w-full flex flex-col gap-4">
              <div className="bg-gray-200 animate-pulse h-48 rounded-lg shadow-md mb-4"></div>
              <div className="h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
              <div className="h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
              <div className="h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
              <div className="h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full lg:w-1/2 p-4">
          <div className="flex-1 h-80 bg-gray-200 mb-4 rounded-lg shadow-md overflow-hidden">
            {loadingMap ? (
           <SpeciesMap coordinates={coordinates} />
            ) : (
              <div className="h-full w-full flex items-center justify-center bg-gray-300 animate-pulse">
              <div className="h-16 w-16 rounded-full bg-gray-400 animate-pulse"></div>
            </div>
            )}
            <h3 className="text-lg font-semibold mb-2 text-gray-800 p-2">Geographical Distribution</h3>
          </div>
          <div className="w-full flex flex-col gap-4">
            {graphData.length ? (
              <>
                <GCard title="Temperature (°C)" graphData={graphData.map(data => ({ date: data.date, value: data.temperature }))} />
                <GCard title="Pressure (hPa)" graphData={graphData.map(data => ({ date: data.date, value: data.pressure }))} />
                <GCard title="Wind Speed (m/s)" graphData={graphData.map(data => ({ date: data.date, value: data.windSpeed }))} />
                <GCard title="Humidity (%)" graphData={graphData.map(data => ({ date: data.date, value: data.humidity }))} />
              </>
            ) : (
              Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="bg-gray-200 animate-pulse h-48 rounded-lg shadow-md mb-4"></div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailSpeciesView;
