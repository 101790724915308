import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const ComboBarChart = ({ data, xLabel, yLabel, groupByKey, xDataKey, yDataKey, barColor }) => {
  const chartRef = useRef();

  useEffect(() => {
    // Sort data by xDataKey
    const sortedData = [...data].sort((a, b) => a[xDataKey] - b[xDataKey]);

    // Set margins and dynamically calculate width and height based on container size
    const margin = { top: 20, right: 30, bottom: 50, left: 70 };
    const containerWidth = chartRef.current.parentElement.clientWidth;
    const width = containerWidth - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    // Clear previous SVG content
    d3.select(chartRef.current).selectAll('*').remove();

    // Create an SVG container with viewBox for responsiveness
    const svg = d3.select(chartRef.current)
      .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const drivers = Array.from(new Set(sortedData.map(d => d[groupByKey])));
    const years = Array.from(new Set(sortedData.map(d => d[xDataKey])));

    const stackedData = years.map(year => {
      const yearData = { year };
      drivers.forEach(driver => {
        const entry = sortedData.find(d => d[xDataKey] === year && d[groupByKey] === driver);
        yearData[driver] = entry ? entry[yDataKey] : 0;
      });
      return yearData;
    });

    const x = d3.scaleBand()
      .domain(years)
      .range([0, width])
      .padding(0.2);

    const y = d3.scaleLinear()
      .domain([0, d3.max(stackedData, d => drivers.reduce((sum, key) => sum + d[key], 0))])
      .nice()
      .range([height, 0]);

    const color = d3.scaleOrdinal()
      .domain(drivers)
      .range(d3.schemeCategory10);

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).tickSize(0));

    svg.append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(y).ticks(5));

    const stack = d3.stack()
      .keys(drivers)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const series = stack(stackedData);

    const bars = svg.selectAll('.series')
      .data(series)
      .enter().append('g')
      .attr('class', 'series')
      .attr('fill', d => color(d.key))
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
      .attr('x', d => x(d.data.year))
      .attr('y', d => y(d[1]))
      .attr('height', d => y(d[0]) - y(d[1]))
      .attr('width', x.bandwidth());

    bars.on('mouseover', function (event, d) {
        const tooltipContent = drivers.map(driver => {
            const value = d.data[driver].toFixed(2); // Display the raw value
            return `<div style="display: flex; align-items: center;">
                      <span style="width: 10px; height: 10px; background-color: ${color(driver)}; margin-right: 5px;"></span>
                      ${driver}: ${value} Mg
                    </div>`;
          }).join('');
    
        d3.select(this)
          .attr('opacity', 0.7);
    
        svg.append('foreignObject')
          .attr('id', 'tooltip')
          .attr('x', x(d.data.year) + x.bandwidth() / 2 - 75)
          .attr('y', y(d[1]) - (drivers.length * 20 + 30))
          .attr('width', 350)
          .attr('height', drivers.length * 20 + 60)
          .style('pointer-events', 'none')
          .append('xhtml:div')
          .style('background-color', '#fff')
          .style('border', '1px solid #ccc')
          .style('box-shadow', '0 4px 8px rgba(0, 0, 0, 0.1)')
          .style('padding', '10px')
          .style('border-radius', '8px')
          .style('font-family', 'Arial, sans-serif')
          .style('font-size', '12px')
          .style('color', '#333')
          .style('text-align', 'left')
          .style('transition', 'all 0.2s ease-in-out')
          .html(tooltipContent);
      })
      .on('mousemove', function (event) {
        d3.select('#tooltip')
          .attr('x', d3.pointer(event)[0] + 10)
          .attr('y', d3.pointer(event)[1] - (drivers.length * 20 + 30));
      })
      .on('mouseout', function () {
        d3.select(this)
          .attr('opacity', 1);
        svg.select('#tooltip').remove();
      });

    // Add x-axis label
    svg.append('text')
      .attr('transform', `translate(${width / 2},${height + margin.bottom - 10})`)
      .style('text-anchor', 'middle')
      .text(xLabel);

    // Add y-axis label
    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 2)
      .attr('y', -margin.left + 20)
      .style('text-anchor', 'middle')
      .text(yLabel);

  }, [data, xLabel, yLabel, xDataKey, yDataKey, groupByKey]);

  return (
    <div className="w-full max-w-4xl mx-auto p-4 bg-white border border-gray-300 rounded-lg shadow-md">
      <div className="h-96">
        <svg ref={chartRef} className="w-full h-full"></svg>
      </div>
    </div>
  );
};

export default ComboBarChart;
