import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const GCard = ({ title, graphData = [] }) => {
  const svgRef = useRef(null);



  useEffect(() => {
    if (!graphData || graphData.length === 0) return;

    const svg = d3.select(svgRef.current);
    const margin = { top: 20, right: 30, bottom: 50, left: 40 };

    const containerWidth = svgRef.current.parentElement.clientWidth;
    const containerHeight = 200; // Increased height to accommodate tooltip

    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    svg.selectAll('*').remove();

    const g = svg
      .attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scalePoint()
      .domain(graphData.map(d => d.date))
      .range([0, width])
      .padding(0.5);

    const y = d3.scaleLinear()
      .domain([0, d3.max(graphData, d => d.value)])
      .range([height, 0]);

    const line = d3.line()
      .x(d => x(d.date))
      .y(d => y(d.value))
      .curve(d3.curveMonotoneX);

    g.append('path')
      .datum(graphData)
      .attr('fill', 'none')
      .attr('stroke', '#69b3a2')
      .attr('stroke-width', 2)
      .attr('d', line);

    const tooltip = g.append('text')
      .attr('class', 'tooltip')
      .attr('x', 0)
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .style('visibility', 'hidden')
      .style('font-size', '12px')
      .style('fill', '#333');

    g.selectAll(".dot")
      .data(graphData)
      .enter().append("circle")
      .attr("class", "dot")
      .attr("cx", d => x(d.date))
      .attr("cy", d => y(d.value))
      .attr("r", 3)
      .attr("fill", "#69b3a2")
      .on('mouseover', function(event, d) {
        tooltip
          .style('visibility', 'visible')
          .text(d.value)
          .attr('x', x(d.date))
          .attr('y', y(d.value) - 10);
      })
      .on('mousemove', function(event, d) {
        tooltip
          .attr('x', x(d.date))
          .attr('y', y(d.value) - 10);
      })
      .on('mouseout', function() {
        tooltip.style('visibility', 'hidden');
      });

    // Create the x-axis
    const xAxis = d3.axisBottom(x);
    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .selectAll("text")
      .style('fill', 'black') // Make x-axis labels black
      .style('font-size', '7.5px'); // Reduce the size of x-axis labels

    // Create the y-axis
    const yAxis = d3.axisLeft(y);
    g.append('g')
      .call(yAxis)
      .selectAll("text")
      .style('fill', 'black'); // Make y-axis labels black

  }, [graphData]);

  return (
    <div className="flex flex-col grow p-4 rounded-xl border border-gray-300 border-solid max-md:px-3 max-md:mt-4 max-md:max-w-full">
      <div className="text-base font-medium leading-6 text-neutral-900">
        {title}
      </div>
      <div className="relative" style={{ paddingBottom: '50%', height: 0, overflow: 'hidden' }}>
        <svg ref={svgRef} className="absolute top-0 left-0 w-full h-full"></svg>
      </div>
     
    </div>
  );
};

export default GCard;
