import React, { useEffect, useState } from 'react';
import LineChart from './LineChart';
import BarChart from './BarChart';
import PieChart from "./PieChart";
import FireMap from './FireMap';
// LoadingComponent to display loading states
const LoadingComponent = () => (
  <div className="border border-blue-300 shadow rounded-md p-4 w-full mx-auto">
    <div className="animate-pulse flex flex-col space-y-4">
      <div className="h-64 bg-gray-300 rounded-md"></div>
      <div className="flex-1 space-y-4">
        <div className="h-2 bg-slate-700 rounded"></div>
        <div className="h-2 bg-slate-700 rounded col-span-1"></div>
        <div className="h-2 bg-slate-700 rounded col-span-2"></div>
      </div>
    </div>
  </div>
);

const fetchFireData = async () => {
  const response = await fetch(
    'https://www.globalforestwatch.org/api/data/dataset/gadm__viirs__iso_weekly_alerts/v20240118/query/?sql=SELECT%20iso%2C%20alert__year%2C%20alert__week%2C%20SUM(alert__count)%20AS%20alert__count%2C%20confidence__cat%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20AND%20confidence__cat%20%3D%20%27h%27%20GROUP%20BY%20iso%2C%20alert__year%2C%20alert__week%2C%20confidence__cat'
  );
  return response.json();
};

const fetchTreeCoverFireData = async () => {
  const response = await fetch(
    "https://www.globalforestwatch.org/api/data/dataset/gadm__tcl__iso_change/v20240404/query/?sql=SELECT%20iso%2C%20umd_tree_cover_loss__year%2C%20SUM(umd_tree_cover_loss__ha)%20AS%20umd_tree_cover_loss__ha%2C%20SUM(umd_tree_cover_loss_from_fires__ha)%20AS%20%22umd_tree_cover_loss_from_fires__ha%22%20FROM%20data%20WHERE%20iso%20%3D%20%27GTM%27%20AND%20umd_tree_cover_density_2000__threshold%20%3D%2030%20%20GROUP%20BY%20umd_tree_cover_loss__year%2C%20iso%20ORDER%20BY%20umd_tree_cover_loss__year%2C%20iso"
  );
  return response.json();
};

const formatDate = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  const suffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Covers 11th - 20th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${day}${suffix(day)} of ${month} ${year}`;
};

function FireAlertsLineChart() {
  const [fireData, setFireData] = useState([]);
  const [maxCount, setMaxCount] = useState(1); // Default to 1 to avoid division by zero
  const [totalAlerts, setTotalAlerts] = useState(0);
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const [peakSeason, setPeakSeason] = useState({ startMonth: '', weeks: 0 });
  const [treeCoverFireData, setTreeCoverFireData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch and process fire data
        const { data } = await fetchFireData();
        const currentDate = new Date();
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

        const weeklyData = data
          .map((d) => {
            const startDate = new Date(d.alert__year, 0, 1); // Start from Jan 1st of the alert year
            const alertDate = new Date(startDate);
            alertDate.setDate(alertDate.getDate() + (d.alert__week - 1) * 7);

            return { date: alertDate, count: d.alert__count };
          })
          .filter((d) => d.date >= oneYearAgo && d.date <= currentDate); // Filter data for the past year

        weeklyData.sort((a, b) => a.date - b.date);

        const max = Math.max(...weeklyData.map(d => d.count));
        setMaxCount(max);

        const total = weeklyData.reduce((sum, d) => sum + d.count, 0);
        setTotalAlerts(total);

        if (weeklyData.length > 0) {
          const end = weeklyData[weeklyData.length - 1].date;
          const start = new Date(end);
          start.setFullYear(end.getFullYear() - 1);

          setDateRange({
            startDate: formatDate(start),
            endDate: formatDate(end),
          });

          const peakStart = new Date(end);
          peakStart.setMonth(peakStart.getMonth() - 2); // Assuming peak season starts 2 months before the end date
          setPeakSeason({
            startMonth: peakStart.toLocaleString('default', { month: 'long' }),
            weeks: 13, // This is a common duration, but you can calculate it dynamically if needed
          });
        }

        setFireData(weeklyData);

        // Fetch tree cover data
        const { data: treeCoverData } = await fetchTreeCoverFireData();
        setTreeCoverFireData(treeCoverData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    loadData();
  }, []);

  return (
    <div className="p-4 space-y-10">
      <div>
        <FireMap/>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-green-500 pb-2">
          Weekly Fire Alerts
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          In <strong>Guatemala</strong>, the peak fire season typically begins in <strong>{peakSeason.startMonth}</strong> and lasts around <strong>{peakSeason.weeks}</strong> weeks. 
          There were <strong>{totalAlerts.toLocaleString()}</strong> VIIRS fire alerts reported between <strong>{dateRange.startDate}</strong> and <strong>{dateRange.endDate}</strong>, considering high confidence alerts only. 
          This is <strong>normal</strong> compared to previous years going back to <strong>2012</strong>.
        </span>
        <LineChart data={fireData} maxCount={maxCount} />
      </div>

      <style>
        {`
          .tooltip {
            position: absolute;
            text-align: left;
            padding: 8px;
            background-color: white;
            border: 1px solid #ddd;
            border-radius: 4px;
            pointer-events: none;
          }
          .dashed-line {
            stroke: #ccc;
            stroke-width: 1;
            stroke-dasharray: 4,4;
          }
        `}
      </style>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-green-500 pb-2">
          Tree Cover Loss Due to Fires in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
          From 2001 to 2023, Guatemala lost 251 kha of tree cover from fires and 1.50 Mha from all other drivers of loss. The year with the most tree cover loss due to fires during this period was 2016 with 53.3 kha lost to fires — 30% of all tree cover loss for that year.
        </span>
        {treeCoverFireData ? (
  <BarChart
    data={treeCoverFireData}
    xLabel="Year"
    yLabel="Tree Cover Loss from Fires (ha)"
    xDataKey="umd_tree_cover_loss__year"
    yDataKey="umd_tree_cover_loss_from_fires__ha"
    barColor="green"
  />
) : (
  <LoadingComponent />
)}
  <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center my-6 text-gray-800 border-b-2 border-blue-500 pb-2">
        Proportion of tree cover loss due to fires in Guatemala
        </h1>
        <span className="block mb-4 text-gray-700 text-lg">
        Fires were responsible for 14% of tree cover loss in Guatemala between 2001 and 2023.
        </span>
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <PieChart
            data={[
              { label: "Tree cover loss from other sources:", value: 1000000 },
              { label: "Tree cover loss from fires", value: 251000 },
  
            ]}
          />
          <div className="flex flex-col space-y-8">
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-blue-500"></div>
              <span>Tree cover loss from other sources: 1.50 Mha</span>
            </span>
            <span className="flex items-center space-x-2">
              <div className="w-5 h-5 bg-red-500"></div>
              <span>Tree cover loss from fires: 251 kha</span>
            </span>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default FireAlertsLineChart;
